
import React from "react";
import { IoLocationSharp } from "react-icons/io5";
import { toggleContactModal } from "../../redux/app";
import { useAppDispatch } from "../../redux/hooks";


interface PlaceCardProps {
  img: string;
  title: string;
  location: string;
  description: string;
  price: number;
  type: string;
  handleOrderPopup: () => void;
  popUp: boolean;
  hidePlaceCardBody?: boolean;
}

const PlaceCard: React.FC<PlaceCardProps> = ({
  img,
  title,
  location,
  description,
  price,
  type,
  handleOrderPopup,
  popUp,
  hidePlaceCardBody = false
}) => {

  const dispatch = useAppDispatch();
  return (
    <>
      <div
        className="shadzow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white cursor-pointer mx-10"
        onClick={() => {popUp ? window.location.href='/pakages-details': dispatch(toggleContactModal(true))}}
      >
        <div className="overflow-hidden">
          <img
          src={img}
          alt=""
          className="mx-auto h-[220px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
          style={hidePlaceCardBody ? { height: "370px", width: "300px", borderRadius: "8px"}:{}}
        />
        </div>         
        <div className="space-y-2 p-3">
          <h1 className="line-clamp-1 font-bold text-xl">{title}</h1>
          {!hidePlaceCardBody &&<>
          <div className="flex items-center gap-2 opacity-70">
            <IoLocationSharp />
            <span>{location}</span>
          </div><p className="line-clamp-2">{description}</p><div className="flex items-center justify-between border-t-2 py-3 !mt-3">
              <div className="flex justify-center">
                <button className="bg-gradient-to-r from-primary to-secondary hover:scale-105 duration-200 text-white py-1 px-4 rounded-full ">
                  Book Now
                </button>
              </div>
              <div>
                <p className="text-2xl font-bold">₹{price}</p>
              </div>
            </div></>}
        </div>
      </div>
    </>
  );
};

export default PlaceCard;

