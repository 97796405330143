import React, { FC } from "react";

export interface CarouselItem3Props {
  label: string;
  image: string;
  packageName: string;
}

export const CarouselItem3: FC<CarouselItem3Props> = ({
  image,
  label,
  packageName,
}) => {
  return (
    <div className="border item rounded-md bg-white">
      <img src={image} className="rounded-t-md h-[200px] w-full " alt={label} />
      <div className="pt-5 flex flex-col justify-center items-center pb-10">
        <p className="text-gray-500 text-sm border-b-2 border-primary-500 py-5">
          {label}
        </p>
        <h6 className="text-xl py-5">{packageName}</h6>
        {/*<button className="bg-white border rounded-md capitalize px-5 py-2 focus:outline-none">*/}
        {/*     know more*/}
        {/*</button>*/}
      </div>
    </div>
  );
};
