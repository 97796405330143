import { createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";

export interface ExpPackageProps {
  image: string;
  label: string;
  packageName: string;
  description: string;
  id: string;
}

export interface PackageProps {
  packages: {
    placeName: string;
    mainDesc: string;
    thumbnail: any;
    content: {
      title: string;
      desc: string;
      images: any[];
    }[];
    conclusion: string;
  }[];
  expPackage: {
    id: number;
    placeName: string;
    mainDesc: string;
    thumbnail: any;
    content: {
      title: string;
      desc: string;
      images: any[];
    }[];
    conclusion: string;
  }[];
}

const initialState: PackageProps = {
  expPackage: [
    {
      id: 1,
      placeName: "Adventure Tours",
      mainDesc:
        "Ladakh is bordered by the Tibet Autonomous Region to the east, the Indian state of Himachal Pradesh to the south, both the Indian-administered union territory of Jammu and Kashmir and the Pakistan-administered Gilgit-Baltistan to the west, and the southwest corner of Xinjiang across the Karakoram Pass in the far north.\n\nThe largest town in Ladakh is Leh, followed by Kargil, each of which headquarters a district.[18] The Leh district contains the Indus, Shyok and Nubra river valleys. The Kargil district contains the Suru, Dras and Zanskar river valleys. The main populated regions are the river valleys, but the mountain slopes also support pastoral Changpa nomads. Leh is the largest city and the joint capital of Ladakh.\n\nMain sites to Visit are Alchi Monastry, Sangam, Hall of Fame, Magnetic Hill, Khardungla, Nubra Valley & Ponganag River amongst others.",
      content: [
        {
          title: "Alchi Monastery",
          desc: "Alchi village is very fertile and it is all green with apricot trees on both side of road.n\nThe Alchi village is famous for the existence of one of the oldest monasteries in Ladakh known as Alchi Monastery, which is Ladakh’s most valuable heritage. Alchi monastery is a Buddhist worshipping place and study center and is managed by the monks of Likir. The monastery was built, according to local tradition, by the great translator Guru Rinchen Zangpo between 958AD and 1055AD.",
          images: [

            require("../../assets/exp-images/image4.jpg"),
            require("../../assets/exp-images/image5.jpg"),
            require("../../assets/exp-images/image6.jpg"),
          ],
        },
        {
          title: "Sangam",
          images: [
            require("../../assets/exp-images/image7.jpg"),
            require("../../assets/exp-images/image8.jpg"),
            require("../../assets/exp-images/image9.jpg"),
          ],
          desc: "Sangam (confluence), located at Nimmu on Leh-Srinagar highway, is the confluence of Indus (Sindhu) and Zanskar rivers.Zanskar river coming from Zanskar valley of Union Territory of Ladakh meets the Indus river at Nimmu and offers a spectacular view to the visitors. Indus is the lifeline of Ladakh which irrigates the dry land of the valley. \n\n\nA large number of tourists visit Sangam point to enjoy the beauty of nature. Also, this is a river rafting point where adventure enthusiasts come to experience thrill of river rafting. The muddy water of a river meets with blue waters of other at Sangam point and offers a beautiful view. ",
        },
        {
          title: "Magnetic Hill",
          desc: "Lying at a distance of around 30 km from Leh, the Magnetic Hill is marked by a yellow signboard which reads “The Phenomenon That Defies Gravity”. It also instructs you to park your vehicles in the box marked with a white point on the road, which is known as the Magnetic Road",
          images: [
            require("../../assets/exp-images/image10.jpg"),
            require("../../assets/exp-images/image11.jpg"),
          ],
        },
        {
          title: "Hall of Fame",
          desc: "Hall of Fame is a museum constructed by the Indian Army in memory of the brave Indian soldiers who laid down their lives defending the motherland in the Indo-Pak wars. The Hall of Fame museum is located on the Leh-Kargil Road, about 4km from the city of Leh. It stands as a reminder of the great sacrifices made by our soldiers to ensure the safety and security of our country.\n\n\n",
          images: [require("../../assets/exp-images/image12.jpg")],
        },
        {
          title: "Nubra Valley",
          desc: "Nubra is the highest motorable road Khardongla pass (18,380ft.) majestic peaks and glaciers enchanting valleys and villages. Diskit and Samstanling Gonpa, Panamik hot spring, double humped camel safari, river rafting, trekking and Sunbathe in the sand dunes at Hunder are also a great source of attraction for the touristsThis valley is popularly known as Ldumra or the valley of orchard/flowers. It is situated in the North of Ladakh, between Karakoram and Ladakh ranges of Himalayas. Nubra lies at average altitude about 10,000 feet above sea level. The climate, of the areas being soft, soil is much fertile and the vegetation of the area is comparatively thicker than those of the other areas of Ladakh. Shrubs, bushes and trees grow in abundance wherever there is any source of water.",
          images: [
            require("../../assets/exp-images/image13.jpg"),
            require("../../assets/exp-images/image14.jpg"),
          ],
        },
        {
          title: "Khardungla Pass",
          desc: "Khardung La, also known as Khardung Pass, is a mountain pass in the Leh district of Ladakh, India. It's located on the Ladakh Range, north of Leh, at an elevation of 5,359 meters (17,582 ft) and connects the Indus and Shyok river valleys. The pass is also the gateway to the Nubra Valley, which leads to the Siachen Glacier",
          images: [
            require("../../assets/exp-images/image15.jpg"),
            require("../../assets/exp-images/image16.jpg"),
            require("../../assets/exp-images/image17.jpg"),

          ],
        },
        {
          title: "Pangong Lake",
          desc: "Pangong Lake or Pangong Tso is a gorgeous high-altitude lake in Ladakh. This Himalayan lake looks like a paradise on earth, it’s that beautiful! Timeless beauty, this Himalayan jewel is set at an altitude of 4350 m above sea level. From its ever-changing shades to the challenging conditions, Pangong Lake is one such attraction that appeals to adventurers, nature lovers, and solitude lovers.\n\nPangong Lake is set in the Himalayas at an elevation of about 4350 m and spans across the borders of India and China.\n\n\nAltitude: It is one of the highest-altitude lakes in the world. The extreme altitude contributes to its unique landscape.\n\n\nLength: Pangong Lake is about 134 km long, making it one of the largest lakes in Asia.\n\n\nPangong Lake, one of the most famous high altitude lakes in Leh Ladakh. This scenic wonder derives its name from the Tibetan word, “Pangong Tso”, which means “high grassland lake”. You could spend hours in contemplation here, and still not have enough of its beauty. Pangong Tso is also known to change colors, appearing blue, green and red at different times of a day. If you have decided to go on a trip to Ladakh anytime soon, don’t forget to visit Pangong Tso Lake. ",
          images: [
            require("../../assets/exp-images/image19.jpg"),
            require("../../assets/exp-images/image20.jpg"),
            require("../../assets/exp-images/image21.jpg"),

          ],
        },
      ],
      conclusion: "",
      thumbnail: [
        require("../../assets/exp-images/image3.jpg"),

      ],
    },
    {
      placeName: "Wild Life",
      mainDesc:
        "Kenya offers the tourist many exciting activities to be enjoyed, with one of the most thrilling being a Hot Air Balloon Safari at the break of dawn, concluding with a 'Champagne Bush Breakfast' in the scenic wilderness of Masai Mara. This truly unique adventure activity is often one of those 'Bucket list' experiences for many travelers visiting Kenya.\n\n\nAnother popular excursion in Kenya, specifically in Masai Mara or Amboseli National Park, is visiting a traditional tribal Maasai village which allows visitors a fascinating insight into the way of life of the famous nomadic Maasai tribe. In addition, one can choose to book everything from guided nature walks, sundowners, bush meals and picnics and horse or camel riding as part of your Safari in Kenya.  ",
      id: 2,
      content: [
        {
          title: "Lake Nakuru",
          desc: "Lake Nakuru is a saline lake in the Great Rift Valley of eastern Africa. It's located in west-central Kenya, south of Nakuru, and is protected by Lake Nakuru National Park. The lake's surface area is 45 square kilometers and its elevation is 1,754 meters above sea level.\n\nLake Nakuru is known for its many species of birds, including millions of pink flamingos that nest along the shores. The lake also has waterbucks, impalas, and hippopotamuses.",
          images: [
            require("../../assets/exp-images/image28.png"),
            require("../../assets/exp-images/image29.jpeg"),
          ],
        },
        {
          title: "Lake Naivasha",
          desc: "Lake Naivasha is a freshwater lake in Kenya, outside the town of Naivasha in Nakuru County, which lies north west of Nairobi. It is part of the Great Rift Valley.\n\n\nLake Naivasha is at the highest elevation of the Kenyan Rift valley at 1,884 metres (6,181 ft) in a complex geological combination of volcanic rocks and sedimentary deposits from a larger Pleistocene Era lake.\n\n\nLocated in Nakuru County, Lake Naivasha is a large freshwater lake which sits just outside the town of Naivasha. Situated at an elevation of 1,884m, Lake Naivasha is the highest lake within the Great Rift Valley. ",
          images: [
            require("../../assets/exp-images/image30.jpeg"),
            require("../../assets/exp-images/image31.jpeg"),
          ],
        },
        {
          title: "Lake Amboseli",
          desc: "Lake Amboseli is a temporary lake found in northwest region from the delta and occupying the largest portion of the park, Lake Amboseli is usually dry most especially in dry season but in wet season period the lake is filled up and at times floods. Lake Amboseli being dry is attributed more to Nyiri desert that stretches along southern border of Kenya to Tanzania, the lake is basin is a habitant to many animal species like elephants more so in dry season when it is dried up. Lake Amboseli is also used as a camping site by adventurous tourists who love camping while in Amboseli national park.",
          images: [
            require("../../assets/exp-images/image32.jpeg"),
            require("../../assets/exp-images/image33.jpeg"),
          ],
        },
        {
          title: "MASAI MARA",
          desc: "Masai Mara National Reserve is located in south west Kenya and is a vast scenic expanse of gently rolling African savannah plains measuring 1510 square kilometers in area and bordering the Serengeti National Park in Tanzania to the south. Masai Mara is a unique wildlife conservation haven famous for its spectacular natural diversity of wildlife and is the premier Kenya Safari location in East Africa, offering visitors numerous reasons to visit this animal paradise. Large numbers of Lions, Cheetah, Elephant, Rhino, African Buffalo, Wildebeest, Giraffe, Zebra and many more animals are found in the park in their natural habitat, unconfined and free to roam the vast Kenyan wilderness stretching for miles on end.",
          images: [
            require("../../assets/exp-images/image26.jpeg"),
            require("../../assets/exp-images/image27.png"),
          ],
        },
      ],
      conclusion: "",
      thumbnail: [require("../../assets/exp-images/image28.png")],
    },
    {
      placeName: "Honeymoon Packages",
      thumbnail: [require("../../assets/exp-images/image34.jpeg")],
      conclusion: "",
      id: 3,
      content: [
        {
          title: "Zanzibar",
          desc: "Zanzibar is a semi-autonomous archipelago of islands in the Indian Ocean, located 25–50 kilometers (16–31 miles) off the coast of Tanzania, East Africa. It's made up of two large islands, Unguja (the main island, also known as Zanzibar) and Pemba, and many smaller islands. The capital of Zanzibar is Zanzibar City, which is located on Unguja\n\n\nZanzibar Island is known as Africa's tropical paradise, offering the world's most beautiful beaches. Here, you find miles and miles of white sandy beaches dotted with palm trees, surrounded by the clear azure water of the Indian Ocean ",
          images: [],
        },
        {
          title: "Amazing Beaches",
          desc: "Nungwi and Bweju beaches are truly beautiful; they are on the only side of the island which is consistently crystal clear water lapping brilliant white sands. The northern beaches really are where to stay in Zanzibar if you are looking for that idyllic Indian Ocean beach experience.",
          images: [require("../../assets/exp-images/image34.jpeg")],
        },
        {
          title: "Bwejuu Beach, Relaxation & Reef Safari",
          desc: "Bwejuu is one of the top beaches in Zanzibar.\n\n\nThis white sandy beach on the island’s southeast coast is lined with palm trees and is only an hour and a half away from Stone Town.\n\n\nIt’s one of the quietest parts of Zanzibar and a popular choice for those seeking total relaxation, peace and privacy. There are plenty of activities and excursions to do around the area, including a mangrove forest and dhow boat trip, a visit to the local village of Bwejuu, a reef safari, snorkelling and diving, a visit to Stone Town, or a day trip to the Jozani Forest, which is home to the Red Colobus Monkey. ",
          images: [
            require("../../assets/exp-images/image35.jpeg"),
            require("../../assets/exp-images/image36.jpeg"),
            require("../../assets/exp-images/image37.jpeg"),

          ],
        },
        {
          title: "Nungwi Beach, a Lively Beach for Night Owls",
          desc: "Nungwi Beach on the northwest coast is one of the most beautiful and popular beaches in Zanzibar and recently made the list of Top 25 Beaches in the World in TripAdvisor’s Traveller’s Choice Awards 2018.\n\n\nNungwi is definitely one of the liveliest areas on the island and the numerous beach resorts, hotels, restaurants and bars offer a variety of night-time entertainment. However, this beach isn’t just for party-goers. The Nungwi coast has fantastic coral reefs which are ideal for diving and snorkelling - you may even be lucky enough to see dolphins, green turtles and, at certain times of the year, whale sharks.\n\n\nFacing west, Nungwi Beach also has some of the best sunsets in Zanzibar and a sunset cruise on a traditional dhow sailing boat is a must.",
          images: [
            require("../../assets/exp-images/image39.jpeg"),
            require("../../assets/exp-images/image40.jpeg"),
            require("../../assets/exp-images/image41.jpeg"),

          ],
        },
      ],
      mainDesc: "",
    },
    {
      id: 1,
      placeName: "Family Package",
      mainDesc:
          "Ladakh is bordered by the Tibet Autonomous Region to the east, the Indian state of Himachal Pradesh to the south, both the Indian-administered union territory of Jammu and Kashmir and the Pakistan-administered Gilgit-Baltistan to the west, and the southwest corner of Xinjiang across the Karakoram Pass in the far north.\n\nThe largest town in Ladakh is Leh, followed by Kargil, each of which headquarters a district.[18] The Leh district contains the Indus, Shyok and Nubra river valleys. The Kargil district contains the Suru, Dras and Zanskar river valleys. The main populated regions are the river valleys, but the mountain slopes also support pastoral Changpa nomads. Leh is the largest city and the joint capital of Ladakh.\n\nMain sites to Visit are Alchi Monastry, Sangam, Hall of Fame, Magnetic Hill, Khardungla, Nubra Valley & Ponganag River amongst others.",
      content: [
        {
          title: "Alchi Monastery",
          desc: "Alchi village is very fertile and it is all green with apricot trees on both side of road.n\nThe Alchi village is famous for the existence of one of the oldest monasteries in Ladakh known as Alchi Monastery, which is Ladakh’s most valuable heritage. Alchi monastery is a Buddhist worshipping place and study center and is managed by the monks of Likir. The monastery was built, according to local tradition, by the great translator Guru Rinchen Zangpo between 958AD and 1055AD.",
          images: [

            require("../../assets/exp-images/image4.jpg"),
            require("../../assets/exp-images/image5.jpg"),
            require("../../assets/exp-images/image6.jpg"),
          ],
        },
        {
          title: "Sangam",
          images: [
            require("../../assets/exp-images/image7.jpg"),
            require("../../assets/exp-images/image8.jpg"),
            require("../../assets/exp-images/image9.jpg"),
          ],
          desc: "Sangam (confluence), located at Nimmu on Leh-Srinagar highway, is the confluence of Indus (Sindhu) and Zanskar rivers.Zanskar river coming from Zanskar valley of Union Territory of Ladakh meets the Indus river at Nimmu and offers a spectacular view to the visitors. Indus is the lifeline of Ladakh which irrigates the dry land of the valley. \n\n\nA large number of tourists visit Sangam point to enjoy the beauty of nature. Also, this is a river rafting point where adventure enthusiasts come to experience thrill of river rafting. The muddy water of a river meets with blue waters of other at Sangam point and offers a beautiful view. ",
        },
        {
          title: "Magnetic Hill",
          desc: "Lying at a distance of around 30 km from Leh, the Magnetic Hill is marked by a yellow signboard which reads “The Phenomenon That Defies Gravity”. It also instructs you to park your vehicles in the box marked with a white point on the road, which is known as the Magnetic Road",
          images: [
            require("../../assets/exp-images/image10.jpg"),
            require("../../assets/exp-images/image11.jpg"),
          ],
        },
        {
          title: "Hall of Fame",
          desc: "Hall of Fame is a museum constructed by the Indian Army in memory of the brave Indian soldiers who laid down their lives defending the motherland in the Indo-Pak wars. The Hall of Fame museum is located on the Leh-Kargil Road, about 4km from the city of Leh. It stands as a reminder of the great sacrifices made by our soldiers to ensure the safety and security of our country.\n\n\n",
          images: [require("../../assets/exp-images/image12.jpg")],
        },
        {
          title: "Nubra Valley",
          desc: "Nubra is the highest motorable road Khardongla pass (18,380ft.) majestic peaks and glaciers enchanting valleys and villages. Diskit and Samstanling Gonpa, Panamik hot spring, double humped camel safari, river rafting, trekking and Sunbathe in the sand dunes at Hunder are also a great source of attraction for the touristsThis valley is popularly known as Ldumra or the valley of orchard/flowers. It is situated in the North of Ladakh, between Karakoram and Ladakh ranges of Himalayas. Nubra lies at average altitude about 10,000 feet above sea level. The climate, of the areas being soft, soil is much fertile and the vegetation of the area is comparatively thicker than those of the other areas of Ladakh. Shrubs, bushes and trees grow in abundance wherever there is any source of water.",
          images: [
            require("../../assets/exp-images/image13.jpg"),
            require("../../assets/exp-images/image14.jpg"),
          ],
        },
        {
          title: "Khardungla Pass",
          desc: "Khardung La, also known as Khardung Pass, is a mountain pass in the Leh district of Ladakh, India. It's located on the Ladakh Range, north of Leh, at an elevation of 5,359 meters (17,582 ft) and connects the Indus and Shyok river valleys. The pass is also the gateway to the Nubra Valley, which leads to the Siachen Glacier",
          images: [
            require("../../assets/exp-images/image15.jpg"),
            require("../../assets/exp-images/image16.jpg"),
            require("../../assets/exp-images/image17.jpg"),

          ],
        },
        {
          title: "Pangong Lake",
          desc: "Pangong Lake or Pangong Tso is a gorgeous high-altitude lake in Ladakh. This Himalayan lake looks like a paradise on earth, it’s that beautiful! Timeless beauty, this Himalayan jewel is set at an altitude of 4350 m above sea level. From its ever-changing shades to the challenging conditions, Pangong Lake is one such attraction that appeals to adventurers, nature lovers, and solitude lovers.\n\nPangong Lake is set in the Himalayas at an elevation of about 4350 m and spans across the borders of India and China.\n\n\nAltitude: It is one of the highest-altitude lakes in the world. The extreme altitude contributes to its unique landscape.\n\n\nLength: Pangong Lake is about 134 km long, making it one of the largest lakes in Asia.\n\n\nPangong Lake, one of the most famous high altitude lakes in Leh Ladakh. This scenic wonder derives its name from the Tibetan word, “Pangong Tso”, which means “high grassland lake”. You could spend hours in contemplation here, and still not have enough of its beauty. Pangong Tso is also known to change colors, appearing blue, green and red at different times of a day. If you have decided to go on a trip to Ladakh anytime soon, don’t forget to visit Pangong Tso Lake. ",
          images: [
            require("../../assets/exp-images/image19.jpg"),
            require("../../assets/exp-images/image20.jpg"),
            require("../../assets/exp-images/image21.jpg"),

          ],
        },
      ],
      conclusion: "",
      thumbnail: [
        require("../../assets/exp-images/family-travel-content.jpg"),

      ],
    },
  ],
  packages: [
    {
      placeName: "dubai",
      thumbnail: [require("../../assets/dubai/dubai2.jpeg")],
      mainDesc:
        "Dubai Luxury Holiday Package is one of the most suitable options to explore Dubai in six nights and seven days. It’s an all-inclusive package which covers accommodation in 3-, 4- & 5-star hotels, daily continental breakfast, transfers and sightseeing within Dubai. Dubai all-inclusive Holiday Package includes two-way airport transfers in private car. Furthermore, it also includes half day Dubai City tour, Creek Dinner Cruise, Evening desert safari, Burj Khalifa at the top, Miracle Garden, Dubai Aquarium and underwater zoo along with full day Abu Dhabi city tour and sightseeing.",
      content: [
        {
          title: "Grand Mosque",
          desc: "When you enter Abu Dhabi's Sheikh Zayed Grand Mosque, you will understand why it is such a special place. Here, people from all walks of life come together to take in the architecture's beauty and gain a deeper understanding of religion and culture in the United Arab Emirates. The mosque is one of the world's largest and was the vision of Sheikh Zayed bin Sultan Al Nahyan - the Founding Father of the UAE. He envisaged the creation of a welcoming and cultural haven that inspires people from all backgrounds. Even the construction was a cultural collaboration. The grand structure was designed and built by skilled teams from all over the world, including 1,300 Iranian artisans who hand-knotted the main prayer hall's carpet - the world's largest.",
          images: [
            require("../../assets/dubai/dubai2.jpeg"),
            require("../../assets/packages-images/dubai/dubai3.jpeg"),
          ],
        },
        {
          title: "Qasr Al Watan",
          desc: "Qasr Al Watan is more than a palace, it is a unique testament to the spirit of the United Arab Emirates' people and its leaders. The Palace invites the world to share our culture, knowledge and inspiring journey. Qasr Al Watan is more than just a palace, it is an enriching interactive journey in a contemporary setting that reveals and reflects on governance, knowledge and craftsmanship. ",
          images: [
            require("../../assets/packages-images/dubai/dubai2.jpeg"),
            require("../../assets/packages-images/dubai/dubai1.png"),

          ],
        },

        {
          title: "Ferrari",
          desc: "Explore four of the world's leading theme parks in a place like no other, Yas Island. Get that Ferrari feeling as you hit G-force speeds on the planet's fastest rollercoaster at Ferrari World Yas Island, Abu Dhabi. Conquer record-breaking rides and slides at Yas Water world Yas Island, Abu Dhabi or have fun in the wave pool at this water wonderland. Kids and adults alike can also escape to a whimsical land of wacky action at Warner Bros. World™ Abu Dhabi. Explore the region’s first marine life theme park at SeaWorld® Yas Island, Abu Dhabi. ",
          images: [
            require("../../assets/packages-images/dubai/dubai5.jpeg"),
            require("../../assets/packages-images/dubai/dubai6.jpeg"),
          ],
        },
        {
          title: "Burj Khalifa",
          desc: "Visiting the Burj Khalifa is often at the top of a traveler’s bucket list of things to do in Dubai. From a spectacular view of the city’s landscape to mouth-watering meals, there’s plenty in store for you at the world’s tallest skyscraper. This page will help you learn everything you need to know before visiting Burj Khalifa - from opening hours and the best time to visit to information about prime and non-prime hours, we've got you covered!",
          images: [
            require("../../assets/packages-images/dubai/dubai7.png"),
            require("../../assets/packages-images/dubai/dubai8.jpeg"),
          ],
        },
        {
          title: "Plam Jumeirah",
          desc: "Dubai is famous for its soaring skyline – and that’s not the only feat of engineering to capture the world’s imagination. Built from reclaimed land in a series of artificial archipelagos, Palm Jumeirah is shaped like a palm tree when viewed from above.",
          images: [
            require("../../assets/packages-images/dubai/dubai10.jpeg"),
            require("../../assets/packages-images/dubai/dubai9.jpeg"),
          ],
        },
        {
          title: "Global Village",
          desc: "At Global Village, we bring the world to Dubai with the best entertainment, shopping, dining experiences, and attractions designed to keep you enthralled all season long! Discover a more wonderful world each time you visit to enjoy myriad cultures, cuisines and conversations.",
          images: [
            require("../../assets/packages-images/dubai/dubai11.jpeg"),
            require("../../assets/packages-images/dubai/dubai12.jpeg"),
          ],
        },
        {
          title: "Dubai Mall Aquarium",
          desc: "Want to explore one of the largest and most stunning aquariums in the world? Don’t miss the giant 10 million-litre tank at Dubai Aquarium and Underwater Zoo, which contains more than 33,000 aquatic animals and the largest collection of sand tiger sharks anywhere. There are numerous ways for visitors to experience the main Aquarium tank, which measures 51 meters in length, 20 meters in width, and 11 meters in height, and is one of the largest suspended aquariums on the planet.",
          images: [
            require("../../assets/packages-images/dubai/dubai13.jpeg"),
            require("../../assets/packages-images/dubai/dubai14.jpeg"),
          ],
        },
        {
          title: "Desert Safari",
          desc: "A must for everyone who is visiting UAE. A memory to share with your family and friends back home, this is one safari you can’t do back home. We recommend it highly and it will be worth every penny spent! This tour starts in the afternoon across the desert of Dubai with several stops for photography. During an exciting dune drive you arrive at the first destination for a beautiful sunset. We stop to watch the sunset and continue with Dune bashing to finally reach our campsite. Here you have the opportunity to do a camel ride, sand boarding and try out a henna design on hand or feet. After working up an appetite you get to enjoy a delicious barbecue dinner and shisha (the famous Arabic water pipe). You also get to enjoy our Fire show and Tanura show performance around the campfire by starlight",
          images: [
            require("../../assets/packages-images/dubai/dubai15.jpeg"),
            require("../../assets/packages-images/dubai/dubai16.jpeg"),
          ],
        },
        {
          title: "Miracle Garden",
          desc: "Get ready to be enchanted by the stunning floral wonderland that is the Dubai Miracle Garden, a one-of-a-kind attraction that boasts over 45 million blooming flowers in a spectacular display of color and creativity. Located in the heart of Dubai, the Miracle Garden is the world's largest flower garden and features a variety of unique designs and structures, including an impressive flower clock, a heart-shaped pathway, and a magnificent floral castle. You'll also get to see the Guinness World Record for the largest flower arrangement in the shape of an Airbus A380.",
          images: [
            require("../../assets/packages-images/dubai/dubai17.jpeg"),
            require("../../assets/packages-images/dubai/dubai18.jpeg"),
          ],
        },
      ],

      conclusion:
        "With a combination of Iranian, Lebanese,  and Arabic food ( Not to forget the Indian taste) we will try and push in some delicacies . For other  gastronomic pleasures you can experience at your leisure  day over candle for two ! The Dubai cuisine will only leave you craving for more.\n\nWhether a foodie or not, you just can’t ignore the luscious flavors that Dubai food has in store for you. The titillating aroma of the piquant delicacies in Dubai will give you a foodgasm for sure. From vegetarians to non-vegetarians, Dubai has something for every foody.\n\nSome dishes will even make you feel home-like as they represent the tradition of their culture. Take a look at all the gastronomical delights that will make your stomach growl with hunger. Some specials shortlisted to beat your hunger pangs.  Manousheh – Pizza of Dubai, Iranian Sangak – One of the Most Popular Dishes,Chelo Kebab – A Heavenly Taste, Al Harees – Taste the Tradition, Al Machboos – Surprisingly Delicious &  Mandi – A Rendezvous with Tradition to name a few. So what are you waiting for! Get ready to plunge and satiate your taste buds!! ",
    },
    {
      placeName: "uttarakhand",
      thumbnail: [require("../../assets/packages-images/uk/uk1.jpeg")],
      mainDesc:
        "A mystical land of mountains and mythologies, exquisite landscapes and exhilarating adventure, and wellness and yoga, Uttarakhand has something to offer every traveller. Popularly known as Devbhoomi, or the land of gods, the state is framed by the Himalayas and divided into two main regions, Garhwal and Kumaon. While Uttarakhand has several well-known destinations like Nainital, Mussoorie, Corbett National Park and Auli, and pilgrimage sites like Kedarnath, Badrinath, Rishikesh and Haridwar, explore the 13 lesser-known destinations from the state's 13 districts.",
      conclusion:
        "Spread over an area of 87 sq km in the Chamoli district, the Valley of Flowers National Park is a UNESCO World Heritage Site and forms one of the two core zones of the Nanda Devi Biosphere Reserve. The valley is believed to have been discovered in 1931, when three British mountaineers – led by Frank S Smythe – lost their way and chanced upon this spectacular valley. Attracted by the beauty of this place they named it the “Valley of Flowers”. As the name suggests, Valley of Flowers is a destination where nature blooms in full glory offering a breathtaking experience. Exotic flowers (over 600 species) like orchids, poppies, primulas, marigold, daisies and anemones are an eye-catching spectacle. Sub-alpine forests birch and rhododendron cover parts of the park's area. The trek to the valley offers eye-catching spectacles like cascading waterfalls and wild streams.  Situated at an altitude of around 3,600 m above the sea level, the valley is also home to such rare and amazing wildlife species like the gray langur, the flying squirrel, the Himalayan weasel, and black bear, the red fox, the lime butterfly, the snow leopard and Himalayan monal, to name a few.",
      content: [
        {
          title: "Rishikesh",
          desc: "Rishikesh, also spelt as Hrishikesh, set against the backdrop of the Himalayas and with the pristine Ganga flowing through it, the ancient town of Rishikesh is one of the major tourist and pilgrimage hubs in northern India, where people from across the world arrive in search of peace. It is known as the Gateway to the Garhwal Himalayas and Yoga Capital of the World, Rishikesh has numerous ashrams, some of which are internationally recognized as centers of philosophical studies, yoga and other ancient Indian traditions of wellness. The destination is abuzz with visitors, who come here to learn yoga and meditation. River rafting in Rishikesh, is the activity that can be the perfect gift for the individual you love. Rafting is a sport known to man of the most exciting and thrilling adventure. Those who have had the pleasure of rafting expeditions to ensure this. Rafting in turbulent waters is an experience that you will keep the rest of his life. In the foothills of the Himalayas, they serve as the perfect setting for Rafting in Rishikesh.",
          images: [
            require("../../assets/packages-images/uk/uk1.jpeg"),
            require("../../assets/packages-images/uk/uk2.jpeg"),
          ],
        },
        {
          title: "Nainital",
          desc: "Nainital, the charming Himalayan Lake town, is a picture-postcard perfect hill-station and one of the most popular in Northern India. Commonly known as the ‘Lake District’, Nainital is nestled high up in the Kumaon Himalayas at an altitude of around 2,000 m above sea level. This beautiful town in surrounded by seven hills, popularly known as ‘Sapta-Shring’ – Ayarpata, Deopata, Handi-Bandi, Naina, Alma, Lariya-Kanta and Sher-Ka-Danda. The majestic mountains and the sparkling waters of the lake add an immense lot to the beauty of the town.  The town is centered around the emerald mountain lake Naini, which on most days is dotted with colorful sailboats. According to mythology, the lake is believed to have been formed when the eyes of goddess “Sati” fell at this spot while her body was being carried by Lord Shiva after her death. ",
          images: [
            require("../../assets/packages-images/uk/uk3.jpeg"),
            require("../../assets/packages-images/uk/uk4.jpeg"),
          ],
        },
        {
          title: "Mussoorie",
          desc: "Mussoorie, also known as Queen of the Hills, is among the most popular hill stations of the country. A Britisher, Captain Frederick Young, accompanied by an official named FJ Shore, had climbed up the hill from the Doon valley in 1827 and found this ridge offering great views and a salubrious climate. This visit laid the foundation for this grand hill station. A captivating paradise for leisure travellers and honeymooners, it is a perfect summer resort. Located on a 15-km-long horseshoe ridge with the grand Himalayas as a backdrop, Mussoorie spreads across at a height of 2,000 m above sea level. From this vantage point, it offers scenic views of Himalayas peaks in Western Garhwal.",
          images: [
            require("../../assets/packages-images/uk/uk5.jpeg"),
            require("../../assets/packages-images/uk/uk6.jpeg"),
          ],
        },
        {
          title: "Jim Corbett National Park",
          desc: "Established in 1936, this is India's first national park. It is named after the legendary naturalist and conservationist Jim Corbett.  Located at the Himalayas' foothills, near the popular hill-station of Nainital, the beautiful Jim Corbett National Park, is famous for being home to a large number of tigers, the highest among any Indian national park. the park is spread over the picturesque landscapes of Pauri Garhwal, Almora, and Nainital. The jeep safari into the dense forest to see the wild animals in their natural habitat is a must-have experience. And if you are lucky, then you may get a chance to even spot a tiger. While on the jeep safari, enjoy the lush green forest's views cut across by streams and rivers and a few waterfalls.",
          images: [
            require("../../assets/packages-images/uk/uk7.jpeg"),
            require("../../assets/packages-images/uk/uk8.jpeg"),
          ],
        },
        {
          title: "Haridwar",
          desc: "One of the holiest pilgrimages in India, Haridwar, or the ‘gateway to gods’, is located where Ganga, the sacred of all Indian rivers, enters the Indo-Gangetic plains. Located at the foothills of the Himalayas, Haridwar is a city of temples and ashrams and its pious ambience envelops everyone. Haridwar is one of the four holy Indian cities that host the Kumbh Mela, a pious gathering of millions of Hindu devotees every 12 years. The Ardh Kumbh is organised here every six years. It also hosts the Kanwar mela every year during the rainy season. The ‘Panch Tirth’ or the five pilgrimages located within the periphery of Haridwar, are Gangadwara (Har Ki Pauri), Kushwart (Ghat), Kankhal, Bilwa Tirtha (Mansa Devi Temple) and Neel Parvat (Chandi Devi). Haridwar serves as the gateway to the Char Dham of Uttarakhand as well. Every morning and evening, the ghats (stepped banks of a river) of River Ganga witnesses the blissful Ganga aarti, which attracts devotees and tourists. The evening ritual being more popular, it makes for a mesmeric sight to see the river being venerated with loud and rhythmic chants and tall lamps, their lights lightening up the darkening waters. It is a spectacular sight as thousands of small diyas (earthen lamps) are set afloat on the river. ",
          images: [
            require("../../assets/packages-images/uk/uk9.jpeg"),
            require("../../assets/packages-images/uk/uk10.jpeg"),
          ],
        },
        {
          title: "Dehradun",
          desc: "Fascinating history and intriguing mythology meet at the crossroads of this beautiful hill-station, one of the most popular in the country. Just 240 km from Delhi, nestled in the rolling Doon Valley and at the foothills of the Himalayas, Dehradun is surrounded by high mountains and lush Sal forests. Known for its pleasant year-round weather and scenic surroundings. Offering a blend of unparalleled landscape and modern amenities, bustling Dehradun is a city for both business and leisure. Once a retirement haven, today it buzzes with excitement, yet has managed to retain its laid-back vibe. Quaint cafés and lounges rub shoulders with heritage monuments and bazaars.",
          images: [
            require("../../assets/packages-images/uk/uk11.png"),
            require("../../assets/packages-images/uk/uk12.jpeg"),
          ],
        },
        {
          title: "Badrinath",
          desc: "The Badrinath Temple also known as the Badrinarayan Temple, located in Uttarakhand's Badrinath town, is one of the Char Dhams (four important pilgrimages) in the state. There are four pilgrim-destinations namely Yamunotri, Gangotri, Kedarnath, and Badrinath, collectively known as Char Dham. These pilgrimage centers draw large number of pilgrims each year, thus becoming the most important hubs of religious travel in the whole of Northern India. Badrinath is located at an elevation of around 3,100 m. Located in the Garhwal Himalayas, on the banks of the Alaknanda River, this sacred town lies between Nar and Narayana Mountain ranges. The temple is believed to have been established by sage Adi Shankaracharya in the 8th century. With Lord Vishnu as its presiding deity, the temple remains open for six months in a year. In winter it becomes inaccessible due to heavy snowfall.",
          images: [
            require("../../assets/packages-images/uk/uk13.jpeg"),
            require("../../assets/packages-images/uk/uk14.jpeg"),
          ],
        },
        {
          title: "Kedarnath",
          desc: "The historical name of this region is Kedar Khand, One of the most revered temple destinations of India, Kedarnath town is nestled in the mighty Garhwal Himalayas. The town, built around the revered Kedarnath temple, is located at an altitude of 3,580 m, near Chorabari glacier, which is the source of the Mandakini River. Dedicated to Lord Shiva, the ancient temple has exquisite architecture and is built of extremely large but evenly shaped grey stone slabs. A conical rock formation inside the temple is worshipped as Lord Shiva in his “Sadashiva” form.  The Kedarnath temple, dedicated to Lord Shiva, is a part of Char Dham pilgrimage circuit, and is one of the 12 Jyotirlingas of Lord Shiva in India. Behind the Kedarnath temple, stand the Kedarnath peak, Kedar Dome and other Himalayan peaks.",
          images: [
            require("../../assets/packages-images/uk/uk15.jpeg"),
            require("../../assets/packages-images/uk/uk16.jpeg"),
          ],
        },
        {
          title: "Auli, India ",
          desc: "Auli, also known as Auli Bugyal, in Garhwali, which means meadow, It is surrounded by coniferous and oak forests. The small yet picture-perfect town of Auli is India's premier ski resort destination. Originally developed as a paramilitary base, Auli's skiing slopes are popular among tourists and professionals alike. In winter, Auli hosts several snow adventure events. Auli offers panoramic views of some of India’s highest peaks, including the second-highest in the country, Nanda Devi (7,816m).  It is also the gateway to the Nanda Devi and the Valley of Flowers National Park, including the Hemkund Sahib gurdwara, and popular trekking destinations such as Gorson Bugyal, Pangerchulla Summit, and Tapovan can be accessed through Auli. Besides Auli, Uttarakhand has several other skiing destinations, like Dayara Bugyal, Munsiyari and Mundali.",
          images: [
            require("../../assets/packages-images/uk/uk17.jpeg"),
            require("../../assets/packages-images/uk/uk18.jpeg"),
          ],
        },
      ],
    },
    {
      placeName: "kerala",
      mainDesc:
        "Kerala is a region of great natural beauty. In the eastern part of the state, Anai Peak (8,842 feet [2,695 metres]), the highest peak of peninsular India, crowns the Western Ghats. Descending from the rocky highlands westward toward the coastal plain is a stretch of farmlands, with different crops cultivated at different elevations. Along the coast, a linked chain of lagoons and backwaters form the so-called Venice of India.",
      conclusion: "",
      thumbnail: [require("../../assets/packages-images/kerala/1.jpeg")],
      content: [
        {
          images: [
            require("../../assets/packages-images/kerala/1.jpeg"),
            require("../../assets/packages-images/kerala/2.jpeg"),
          ],
          title: "kerala",
          desc: "The urban councils of Kerala date back to the 17th century when the Dutch Malabar established the municipality of Fort Kochi, making it the first municipality in the Indian subcontinent. Kannur, Thalassery, Kozhikode, Palakkad were parts of Malabar District until 1956, The Thiruvananthapuram Municipality came into existence in 1920.",
        },
        {
          title: "Kerala Backwaters",
          desc: "The Kerala backwaters are a network of brackish lagoons and canals lying parallel to the Arabian Sea of the Malabar coast of Kerala state in south-western India. It also includes interconnected lakes, rivers, and inlets, a labyrinthine system formed by more than 900 km (560 mi) of waterways, and sometimes compared to bayous The network includes five large lakes linked by canals, both man made and natural, fed by 38 rivers, and extending virtually half the length of Kerala state. The backwaters were formed by the action of waves and shore currents creating low barrier islands across the mouths of the many rivers flowing down from the Western Ghats range. In the midst of this landscape there are a number of towns and cities, which serve as the starting and end points of backwater cruises. There are 34 backwaters in Kerala. Out of it, 27 are located either closer to Arabian Sea or parallel to the sea. The remaining 7 are inland navigation routes.",
          images: [
            require("../../assets/packages-images/kerala/3.jpeg"),
            require("../../assets/packages-images/kerala/4.jpeg"),
          ],
        },
        {
          title: "Tea Gardens",
          desc: "The history of tea plantation in Kerala is associated with the Britishers. They were trying to vie with the tea trade of China. They found that the slopes of the Western Ghats could be the prime tea plantation site in India. The weather, the soil and the long history of the know-how in plantation of spices made the slopes of the Ghats a suitable location for tea plantation in India. Thus, Kerala came to occupy a prominent place among tea grower states of India. Tea plantation in Kerala is spread over Idukki, Wayanad, Kottayam, Thrissur, Malappuram and Palakkad but Idukki and Wayanad are the two major tea producing regions by way of total acreage under tea. 87 % of the total area under tea gardens in Kerala falls in these two districts. Idukki is the most important district with 72% of the total acreage of Kerala under tea plantations here. The main tea growing areas of Idukki are in Munnar, Vandiperiyar and Peermade regions. Wayanad accounts for about 14% other than areas under multi plantations like Palghat-Nelliampathy and Thiruvananthapuram.",
          images: [
            require("../../assets/packages-images/kerala/5.jpeg"),
            require("../../assets/packages-images/kerala/6.jpeg"),
          ],
        },
        {
          title: "Beaches",
          desc: "Kerala's landscape is adorned top to bottom by a plethora of beaches that cater to locals and visitors alike. Beaches in the Indian state of Kerala are spread along the 550-km Arabian Sea coastline. The topography of the coastline is distinctive and changes abruptly as one proceeds from north to south. In the northern parts of Kerala, in places such as Bekal, Thalassery and Kannur, the headlands rise above the shore from the fringe of the beaches. The highlands are dotted with forts built by the colonial powers – the Portuguese, the Dutch and the British. The view of the surrounding area is mesmerizing. From Kozhikode, once the hub of the Malabar coast, the view changes to flat lands with rocky outcroppings jutting out. One feature is common all through – the coconut tree in large numbers. Dense groves of coconut trees line the coast and extend to the interiors.",
          images: [
            require("../../assets/packages-images/kerala/7.jpeg"),
            require("../../assets/packages-images/kerala/8.jpeg"),
          ],
        },
        {
          title: "Waterfalls",
          desc: "Kerala, the mesmerizing land of natural wonders, is blessed with an abundance of scenic beauty and is popular for having numerous majestic waterfalls. Among its many enchantments, the beautiful waterfalls of Kerala stand tall, captivating visitors with their breathtaking cascades and pristine surroundings. These scintillating cascades are famous picnic spots and excursion destinations round the year. From gushing torrents to serene cascades, these waterfalls offer a picturesque retreat for nature enthusiasts and adventurers alike. The luscious Kerala waterfalls are a sight your eyes will never tire of feasting upon. There are around 27 waterfalls in Kerala, which justifies why this place is called God’s own country. ",
          images: [
            require("../../assets/packages-images/kerala/9.jpeg"),
            require("../../assets/packages-images/kerala/10.jpeg"),
          ],
        },
        {
          title: "Hill Stations",
          desc: "Kerala, known for its lush landscapes, boasts several picturesque hill stations. These tranquil retreats offer respite from the coastal heat and feature cool, misty environments, dense forests, and cascading waterfalls. Visitors can explore tea and spice plantations, go trekking in the Western Ghats, and experience the rich local culture. Each hill station in Kerala offers a unique blend of natural beauty and cultural charm, making them popular destinations for tourists seeking a refreshing escape. In this article, we will be discussing about the top hill stations in Kerala. Some of the famous hill stations in Kerala include Munnar, Wayanad, Vagamon, Thiruvambadi, Lakkidi, Thekkady and many more. ",
          images: [
            require("../../assets/packages-images/kerala/11.jpeg"),
            require("../../assets/packages-images/kerala/12.jpeg"),
          ],
        },
      ],
    },
    {
      placeName: "SINGAPORE",
      thumbnail: [require("../../assets/packages-images/singapour/1.jpeg")],
      mainDesc:
        "Singapore is a sunny, tropical island in Southeast Asia, off the southern tip of the Malay Peninsula. It is about 275 square miles, smaller than the State of Rhode Island, and inhabited by over five million people, of which the resident population comprises four major communities; Chinese, Malay, Indian and Eurasian. Ideally positioned in South-east Asia, where the rest of the region is just a hop, skip and jump away, Singapore is a thriving metropolis offering a world-class infrastructure, a fully integrated island-wide transport network, dynamic business environment, vibrant living spaces and a rich culture largely influenced by the four major communities in Singapore with each offering different perspectives of life in Singapore in terms of culture, religion, food, language and history.",
      conclusion: "",
      content: [
        {
          title: "Gardens by the Bay",
          desc: "An integral part of Singapore's City in a Garden vision, Gardens by the Bay spans a total of 101 hectares of prime land at the heart of Singapore's new downtown - Marina Bay. Comprising three waterfront gardens - Bay South, Bay East and Bay Central - Gardens by the Bay will be a showcase of horticulture and garden artistry that will bring the world of plants to Singapore and present Singapore to the World.",
          images: [
            require("../../assets/packages-images/singapour/1.jpeg"),
            require("../../assets/packages-images/singapour/2.jpeg"),
          ],
        },
        {
          title: "Singapore Botanic Gardens",
          desc: "This tranquil oasis is the perfect place to escape the hustle and bustle of the city and relax in the lush tropical climate. The winding pathways are a delight to explore, with a wide variety of flora and fauna to discover. The vibrant birdlife is a sight to behold, and the educational tours offered by the Gardens are a great way to learn more about the natural environment.",
          images: [
            require("../../assets/packages-images/singapour/3.jpeg"),
            require("../../assets/packages-images/singapour/4.jpeg"),
          ],
        },
        {
          title: "Universal Studios Singapore",
          desc: "Universal Studios Singapore® offers visitors elaborate entertainment: seven themed zones based on popular movies and TV shows, each with 4D experiences and adventures of their own. Get access to all of them with a 1-day pass, and focus on seeing as many of the park’s attractions as possible. Plus, the optional transfer from your hotel means you won’t have to worry about getting to the park on your own.",
          images: [
            require("../../assets/packages-images/singapour/5.jpeg"),
            require("../../assets/packages-images/singapour/6.jpeg"),
          ],
        },
        {
          title: "Singapore Zoo",
          desc: "Set in a rainforest environment, Singapore Zoo's world-famous Open Concept offers the opportunity to experience and be inspired by the wonders of nature. Home to more than 2,400 specimens of over 300 species, 34 per cent of which are threatened, the Zoo has attained a strong reputation internationally for its conservation initiatives and breeding programmes. To better meet the healthcare needs of its animals and working towards its aspiration to become a leading global centre of excellence for veterinary healthcare and research, a purpose-built Wildlife Healthcare and Research Centre was set up in March 2006. Annually, approximately 1.9 million visitors enjoy experiential learning journeys at the 26-hectare award-winning Zoo. Singapore Zoo is part of Wildlife Reserves Singapore. The Zoo is a designated rescued wildlife centre by the governing authority.",
          images: [
            require("../../assets/packages-images/singapour/7.jpeg"),
            require("../../assets/packages-images/singapour/8.jpeg"),
          ],
        },
        {
          title: "Marina Bay",
          desc: "The Marina Bay area has been developed over the years to become Singapore's new downtown for the 21st century, with a financial centre, civic space and gardens situated within its boundaries. Marina Bay truly comes into its own at night. Discover the Bay—and so much more—on this guided evening bike tour. Pedal past the Singapore River, the towering neon-lit “Super Trees” by Gardens on the Bay, the Singapore Flyer wheel, Marina Bay Sands, and so much more. Your bike comes with a helmet, poncho, and water.",
          images: [
            require("../../assets/packages-images/singapour/9.jpeg"),
            require("../../assets/packages-images/singapour/10.jpeg"),
          ],
        },
        {
          title: "Merlion Park",
          desc: "Merlion Park is named for its centerpiece, the Merlion statue, which spouts water into Marina Bay. the Merlion is the national icon of Singapore. The park is also popular with locals, who come here to play and relax along the waterfront. The Merlion is the official mascot of Singapore. It is depicted as a mythical creature with the head of a lion and the body of a fish. Being of prominent symbolic nature to Singapore and Singaporeans in general.",
          images: [
            require("../../assets/packages-images/singapour/11.jpeg"),
            require("../../assets/packages-images/singapour/12.jpeg"),
          ],
        },
        {
          title: "Little India Singapore",
          desc: "For subcontinental color, cuisine, and atmosphere, head to Singapore’s Little India, one of the island’s most vibrant districts. Shops, restaurants, street vendors, and colorful Hindu temples line the streets of Little India, making it an excellent place to take a walk. The culture and community center of Little India, Sri Veeramakaliamman Temple is the neighborhood’s most important Hindu Temple, dedicated to the goddess Kali.",
          images: [
            require("../../assets/packages-images/singapour/13.jpeg"),
            require("../../assets/packages-images/singapour/14.jpeg"),
          ],
        },
        {
          title: "Singapore Chinatown",
          desc: "For many visitors, Singapore’s Chinatown is the sightseeing focus of the city, home to traditional shophouses, temples, and cultural heritage. Take a wander down the atmospheric streets, dropping into shophouses to see what’s for sale. Admire the rooftop dragons of Thian Hock Keng Temple, dedicated to the Goddess of the Sea, and the festively gaudy Hindu Sri Mariamman Temple, covered with colorful cows and depictions of the gods. Of course, Chinatown is also the place to go to for great food, especially along Smith Street. The imposing, five-story Tang-style Buddha Tooth Relic Temple was constructed entirely around a single tooth—not just any tooth, but that believed to be the canine tooth of the Buddha. The sacred relic was discovered by the Venerable Cakkapala and is now enshrined at the temple",
          images: [
            require("../../assets/packages-images/singapour/15.jpeg"),
            require("../../assets/packages-images/singapour/16.jpeg"),
          ],
        },
        {
          title: "Singapore Flyer",
          desc: "At 165 metres tall, Singapore Flyer is a masterpiece of urban architecture and engineering that showcases not only the mesmerizing cosmopolitan cityscape of the tropical Lion City, but even the surrounding islands of Indonesia and parts of Malaysia in all their glory. In addition to offering panoramic views of Singapore's cosmopolitan cityscape, guests can also indulge in a flute of champagne, or savour the iconic Singapore Sling whilst hosted in a special themed capsule.",
          images: [
            require("../../assets/packages-images/singapour/17.jpeg"),
            require("../../assets/packages-images/singapour/18.jpeg"),
          ],
        },
        {
          title: "Night Safari",
          desc: "The Night Safari, Singapore is the world's first nocturnal zoo located in Mandai, Singapore. One of the most popular tourist attractions in the country, it forms a part of the Mandai Wildlife Reserve, consisting of the Singapore Zoo, Bird Paradise and River Wonders and the upcoming Rainforest Wild Park. As dusk falls, get ready as over 1,000 nocturnal animals start their nightly rituals. Come up close to them as they frolic, graze and hunt. With an exciting tram ride that takes you through 7 geographical regions and more, embark on a fascinating journey through the world's very first wildlife night park.",
          images: [
            require("../../assets/packages-images/singapour/19.jpeg"),
            require("../../assets/packages-images/singapour/20.jpeg"),
          ],
        },
        {
          title: "Singapore Cable Car",
          desc: "The breath-taking connection between Faber Peak Singapore and Sentosa Island Established since 1974, Singapore Cable Car is the nation's first and only cableway that links Faber Peak on mainland Singapore to the island resort of Sentosa, before flying you to the Merlion or the sandy beach at Siloso. Today, the cable car rides are enjoyed across a Cable Car Sky Network of more than 100 cabins spanning almost 5 kilometres on the Mount Faber Line and the Sentosa Line. A 'joyride' across the Cable Car Sky Network offers a 360-degree aerial and visual treat of the entire Sentosa-HarbourFront skyline and the resort island. The scenery transforms as the cabins soar above the forest, through a skyscraper, over the harbour and travel across the jungle, sand and sea. Our passionate service ambassadors are on hand to create happy moments for all cable car joyriders.",
          images: [
            require("../../assets/packages-images/singapour/21.jpeg"),
            require("../../assets/packages-images/singapour/22.jpeg"),
          ],
        },
        {
          title: "National Museum of Singapore",
          desc: "With a history dating back to its inception in 1887, the National Museum of Singapore is the nation's oldest museum with a progressive mind. Its galleries adopt cutting-edge and multi-perspective ways of presenting history and culture to redefine conventional museum experience. A cultural and architectural landmark in Singapore, the Museum hosts innovative festivals and events all year round-the dynamic Night Festival, visually arresting art installations, as well as amazing performances and film screenings-in addition to presenting thought-provoking exhibitions involving critically important collections of artefacts. The programming is supported by a wide range of facilities and services including F&B, retail and a Resource Centre. The National Museum of Singapore re-opened in December 2006 after a three-year redevelopment, and celebrated its 125th anniversary in 2012. The Museum refreshed its permanent galleries and re-opened them on 19 September 2015 for Singapore's Golden Jubilee.",
          images: [
            require("../../assets/packages-images/singapour/23.jpeg"),
            require("../../assets/packages-images/singapour/24.jpeg"),
          ],
        },
        {
          title: "Madame Tussauds Museum",
          desc: "Madame Tussauds Museum in Singapore brings world-famous celebrities and historical icons to life through astonishingly lifelike wax figures. This is one of the top places to visit in Singapore, if you want to get up close and personal with your favorite stars, sports legends, and political figures. This museum offers an interactive and entertaining experience as you explore its various themed zones.",
          images: [
            require("../../assets/packages-images/singapour/25.jpeg"),
            require("../../assets/packages-images/singapour/26.jpeg"),
          ],
        },
        {
          title: "Adventure Cove Waterpark & S.E.A Aquarium",
          desc: "Adventure Cove Waterpark is where aquatic adventures meet endless fun. This waterpark is part of Resorts World Sentosa and promises a day filled with thrilling water rides and marine encounters. Dive into the heart-pounding water slides, float along the lazy river, or snorkel with colorful marine life in the Rainbow Reef, this Singapore Tourist Places offers perfect water adventure.  If you want to explore the underwater world, then S.E.A Aquarium is a must-visit Singapore tourist places for you. It’s one of the world’s largest aquariums, offering a mesmerizing journey through diverse aquatic habitats. From colorful coral reefs to awe-inspiring open ocean tanks, you’ll encounter a stunning array of marine species.",
          images: [
            require("../../assets/packages-images/singapour/27.jpeg"),
            require("../../assets/packages-images/singapour/28.jpeg"),
          ],
        },
      ],
    },
    {
      placeName: "TURKEY",
      mainDesc: "",
      conclusion: "",
      thumbnail: [require("../../assets/packages-images/turkey/1.jpeg")],
      content: [
        {
          title: "Dolmabahçe Palace (Dolmabahçe Sarayı)",
          desc: "It’s time to take a leisurely walk down to Beşiktaş to explore the gorgeous rooms and grounds of the Dolmabahçe Palace, the largest palace in Turkey. Alternatively, you can take the 30A bus (which is every 15 minutes) from the bus stop near Maçka Park down to the palace. The entrance fee to the palace includes access to the palace harem and the National Palaces Painting Museum which has around 200 paintings from 19th-century Turkish and international artists on display. Don’t miss visiting the gorgeous Baroque-style Dolmabahçe Mosque (Dolmabahçe Camii) close to the palace - even in the summer you can find it totally free of crowds. Head along the coast by bus (22, 25E or 30D) towards Ortaköy and visit the stunning Ortaköy Mosque (Ortaköy Camii) on the waterfront. Built by the same Armenian architects who designed the Dolmabahçe Palace and Mosque, it’s one of the prettiest mosques in Istanbul.",
          images: [
            require("../../assets/packages-images/turkey/1.jpeg"),
            require("../../assets/packages-images/turkey/2.jpeg"),
          ],
        },
        {
          title: "Turkish bath, Karaköy",
          desc: "A Turkish bath (hamam) is one of the things we recommend doing in Istanbul and Karaköy has one of the best places to try this quintessential Turkish experience. Kılıç Ali Paşa Hamamı is a stunning 16th century Turkish bath that has a hammam ritual fit for a queen (or king!). After relaxing in a marble steam room, you will be massaged, scrubbed and have your hair washed. You can then relax on the comfortable sofas outside with snacks and a cup of Turkish çay, feeling completely reborn.",
          images: [
            require("../../assets/packages-images/turkey/3.jpeg"),
            require("../../assets/packages-images/turkey/4.jpeg"),
          ],
        },
        {
          title: "Galata Neighbourhood",
          desc: "Galata is famous for its many picturesque cobbled streets, neoclassical buildings, cafes, art galleries and independent shops. The star attraction is of course the 700-year-old Galata Tower. From the tower’s observation deck, you can see a 360° panoramic view over Istanbul’s rooftops and famous mosques, the Golden Horn, and the Bosphorus. There are also museum exhibits on several of the floors that are worth checking out - don’t miss seeing parts of the chain that used to block enemy ships from entering the Golden Horn in the 8th century. End the day by heading back down to Karaköy and watching the sunset over the Golden Horn from the Galata Bridge. It’s spectacular at all times of the year!",
          images: [
            require("../../assets/packages-images/turkey/7.jpeg"),
            require("../../assets/packages-images/turkey/6.jpeg"),
            require("../../assets/packages-images/turkey/5.jpeg"),
          ],
        },
        {
          title: "The Hagia Sophia (Hagia Sophia Camii)",
          desc: "Built by Emperor Justinian I in 537AD it was the world’s largest cathedral for almost 1000 years and is considered to be the pinnacle of Byzantine architecture. Inside the Hagia Sophia, you will see a combination of Christian and Islamic iconography. There are beautifully designed golden mosaics and frescoes on both the upper and lower galleries",
          images: [
            require("../../assets/packages-images/turkey/8.jpeg"),
            require("../../assets/packages-images/turkey/9.jpeg"),
          ],
        },
        {
          title: "The Blue Mosque (Sultanahmet Camii)",
          desc: "Across Sultanahmet Square and opposite the Hagia Sophia is the stunning Blue Mosque. Commissioned by the 19 year old Sultan Ahmed I in the early 17th century, the mosque took seven years to build and was finally completed in 1616. Combining elements of Byzantine and Ottoman design, the Blue Mosque gets its name from the 22,000 handmade blue Iznik tiles that cover the walls and arches of the mosque. There are apparently more than 50 different tulip designs!  With over 200 stained glass windows and many chandeliers, it’s easy to spend time in the Blue Mosque looking up and being awestruck by its beauty.",
          images: [
            require("../../assets/packages-images/turkey/10.jpeg"),
            require("../../assets/packages-images/turkey/11.jpeg"),
          ],
        },
        {
          title: "Basilica Cistern",
          desc: "The Basilica Cistern reopened in 2022 after an extensive renovation. Built by the Emperor Justinian I (yes, the same Emperor that built the Hagia Sophia) to supply water to the Great Palace, the cistern consists of 336 thirty-foot marble columns and can store 80,000 cubic metres of water. The largest cistern in Istanbul, it contains an artificial lake that’s the size of two football fields. The biggest attraction in the cistern is undoubtedly the two upside-down Medusa heads. There are also various art installations in the cistern - including some stunning sculptures. The lighting inside also changes continually so it’s variously lit in blue, red and green hues and at some points fades totally to black, so watch your step if you don’t want to end up in the water!",
          images: [
            require("../../assets/packages-images/turkey/13.jpeg"),
            require("../../assets/packages-images/turkey/14.jpeg"),
            require("../../assets/packages-images/turkey/12.jpeg"),
          ],
        },
        {
          title: "Topkapı Palace (Topkapı Sarayı)",
          desc: "Originally the main residence of the Ottoman sultans and the administrative headquarters of the Ottoman Empire, the Topkapı Palace was constructed in the 15th century by Sultan Mehmed the Conquerer. The rooms of the palace are magnificently decorated, with intricately detailed tiles, stained glass windows and doorways and walls decorated with gold. Don’t miss a visit to the Harem, where the Sultan’s wives, mother and as many as 300 concubines lived. The rooms in this section are the most stunning in the palace. You should also stop at the Imperial Treasury which features a collection of items made with precious metals and stones such as rubies, gold, diamonds and pearls. Amongst these you’ll find the Spoonmaker’s Diamond, a whopping 86 carat diamond in the shape of a teardrop, that is one of the largest diamonds in the world!",
          images: [
            require("../../assets/packages-images/turkey/15.jpeg"),
            require("../../assets/packages-images/turkey/16.jpeg"),
          ],
        },
        {
          title:
            "The Grand Bazaar (Kapalı Çarşı) & The Spice Bazaar (Mısır Çarşışı)",
          desc: "Shopaholics are sure to love the Grand Bazaar! The largest covered market in the world, the Grand Bazaar consists of over 4000 shops and 60 streets and alleys. Everything is sold here, from Turkish rugs, brightly coloured lanterns, metalware and jewellery to ceramics, scarves and hamam accessories. Given the size of the place, the crowds and the vendors constantly calling out to you, it can feel a bit overwhelming: The key thing is to not take it all too seriously and if you do want to buy something, except to haggle, because it’s the Turkish way! Down the road from the Grand Bazaar, the Spice Bazaar is a food lovers paradise. Colourful and fragrant, this is the place to pick up your dried fruit, nuts, Turkish sweets and herbal teas.         ",
          images: [
            require("../../assets/packages-images/turkey/17.jpeg"),
            require("../../assets/packages-images/turkey/18.jpeg"),
            require("../../assets/packages-images/turkey/19.jpeg"),
            require("../../assets/packages-images/turkey/20.jpeg"),
          ],
        },
        {
          title: "Kadıköy, Yeldeğirmeni and Moda Neighbourhoods",
          desc: "The best thing to do here is to explore on foot. Wander around the lovely pedestrianised Bahariye Caddesi and explore its cafes, antique shops and trendy clothing stores. There are also a few attractions in this area that you can visit,Kadıköy is famous for its long, walkable seaside promenade, large green parks and its 14km shopping street Bağdat Caddesi. Packed with cute cafes, affordable restaurants, bars, shops and plenty of students, this area has a bohemian and youthful vibe.   North of Kadıköy centre is Yeldeğirmeni, it was also the first area in Kadıköy to have apartment-style buildings. For several years, Yeldeğirmeni was one of the main locations for the Mural Istanbul festival, which started in 2012. The murals are fantastic and exceptionally detailed. We’d suggest just walking around this area and seeing what you stumble upon. The Moda area also has some lovely parks - Moda Sahili Parki (Moda Seaside Park) hugs the coastline and has a promenade that stretches on for miles. It’s perfect for a late afternoon stroll as the sun is going down. You can also rent one of the free bikes in Kadıköy (known as Isbike) and cycle along the seafront! Many people like to relax at Moda Çay Bahçesi, a tea garden that overlooks the sea of Marmara and another great sunset spot.  ",
          images: [
            require("../../assets/packages-images/turkey/21.jpeg"),
            require("../../assets/packages-images/turkey/22.jpeg"),
            require("../../assets/packages-images/turkey/23.jpeg"),
            require("../../assets/packages-images/turkey/24.jpeg"),
          ],
        },
        {
          title: "Goreme Open Air Museum",
          desc: "This museum is a great introduction to the history of Göreme and features beautiful examples of rock cut churches and restored frescoes from the Byzantine period. Göreme was the centre of religious activity for many years and is believed to have initially been a Christian settlement for monks that eventually became a major pilgrimage site. There are 15 churches in the Goreme Open Air Museum alone (some of which date back to the 10th century) whilst in the wider Göreme area there are 60 churches, innumerable burial mounds and chapels. Everywhere you look you’ll also find the remains of chapels, monasteries and the living quarters of Byzantine monks, plus kitchens and dining areas.",
          images: [
            require("../../assets/packages-images/turkey/25.jpeg"),
            require("../../assets/packages-images/turkey/26.jpeg"),
          ],
        },
        {
          title: "Historical Ruins of Ephesus",
          desc: "Ephesus is an ancient city in Turkey’s Central Aegean region, near modern-day Selçuk. Its excavated remains reflect centuries of history, from classical Greece to the Roman Empire – when it was the Mediterranean’s main commercial center – to the spread of Christianity. Paved streets wind past squares, baths and monumental ruins. The Temple of Hadrian was built before 138 A.D. for Emperor Hadrian’s visit. Ephesus became a UNESCO World Heritage Site in 2015 and is one of the top 10 places to visit in Turkey.",
          images: [
            require("../../assets/packages-images/turkey/27.jpeg"),
            require("../../assets/packages-images/turkey/28.jpeg"),
          ],
        },
        {
          title: "Pamukkale Thermal Pools",
          desc: "The stunning travertine pools at Pamukkale, over thousands of years, the many layers of calcium carbonate crystallise into a hard rock formation called travertines and which look like steps or terraced pools. Since Roman times, the mineral-filled thermal waters of Pamukkale have been thought to have healing properties and medicinal benefits. The Roman city of Hierapolis, built above the travertine springs, was founded as a thermal spa city in the 2nd century BC. Advised by their doctors, Roman citizens from everywhere would come to treat their ailments. Even today, the water is believed to cure all sorts of medical issues from eczema and cardiovascular problems to rheumatism.",
          images: [
            require("../../assets/packages-images/turkey/29.jpeg"),
            require("../../assets/packages-images/turkey/30.jpeg"),
          ],
        },
        {
          title: "Hierapolis Ancient Theatre",
          desc: "Even though you’ve already been wowed by Ephesus, the massive Hierapolis ancient amphitheatre is a spectacular site to see. Built in the 2nd Century AD by Emperor Hadrian (the same Emperor who built the famous Hadrian’s Wall in Britain), this amphitheatre could accommodate almost 15,000 people. The 45 rows of seating were divided into upper and lower sections, with some seats being specifically reserved for priests or other important guests. The ruins of the ancient city of Hieropolis are fantastic to explore and there’s also a great museum.",
          images: [
            require("../../assets/packages-images/turkey/31.jpeg"),
            require("../../assets/packages-images/turkey/32.jpeg"),
          ],
        },
        {
          title: "Fethiye Town & Ölüdeniz Beaches",
          desc: "Fethiye is a port city, and district, on Turkey's southwestern Turquoise Coast. It's known for its natural harbor, blue waters and numerous rock tombs including the 4th-century B.C. Tomb of Amyntas, carved into a bluff overlooking the city. Near-shore islands are popular for day trips by boat. Located at the southern end of Ölüdeniz, a beach known for its crystal-clear waters and golden sands, the Blue Lagoon is a corner of paradise with its turquoise sea and natural beauty. Situated within the Ölüdeniz Nature Park, the Blue Lagoon's allure is being preserved. Ölüdeniz Beach and the Blue Lagoon provide an excellent environment for water sports and outdoor activities. Additionally, the 1960-meter-high (6,430-foot-high) Babadağ mountain in the region is a world-famous paragliding center.",
          images: [
            require("../../assets/packages-images/turkey/33.jpeg"),
            require("../../assets/packages-images/turkey/34.jpeg"),
          ],
        },
        {
          title: "Butterfly Valley & St Nicolas Island",
          desc: "Another possible activity is a trip to Butterfly Valley viewpoint or to actually visit the valley itself on a boat tour. The stunning valley was once home to 105 species of butterfly and is located 7km from Ölüdeniz. The steep cliffs of the valley surround a narrow stretch of white sandy beach that looks like a slice of paradise. Butterfly Valley was once an off-the-beaten path spot but is now a very popular tourist destination so you can expect the viewpoint to be crowded. St Nicolas Island (also known as Gemiler Island) You’ll then sail to St Nicholas’ island and explore the ruins of several Byzantine Greek churches and Christian tombs. Many archaeologists believe that St Nicholas (yes, that Nicholas aka Father Christmas) was buried on the island.",
          images: [
            require("../../assets/packages-images/turkey/35.jpeg"),
            require("../../assets/packages-images/turkey/36.jpeg"),
          ],
        },
        {
          title: "Kayaköy",
          desc: "Today Kayaköy is a popular tourist attraction: for a small fee you can wander around the 500 buildings that remain. It’s an eerie place but still charming - the narrow-cobbled streets are picturesque and in the churches you can admire the pebble floor mosaics, weathered frescoes and wall paintings. It’s also a great place to watch a stunning sunset - when you take in the views you can understand why people chose to live here.",
          images: [
            require("../../assets/packages-images/turkey/39.jpeg"),
            require("../../assets/packages-images/turkey/40.jpeg"),
          ],
        },
      ],
    },
    {
      placeName: "bali",
      conclusion: "",
      mainDesc:
        "An exotic tropical destination, also known as the Land of the Gods, Bali appeals through its sheer natural beauty of looming volcanoes and lush terraced rice fields that exude peace and serenity. It is also famous intricately carved temples. Bali enchants with its dramatic dances and colorful ceremonies, its arts, and crafts, to its luxurious beach resorts and exciting nightlife.",
      content: [
        {
          title: "Seminyak",
          desc: "While we’re still talking of beaches, let’s not forget Seminyak. It’s a scenic and refined beach resort of sorts with villas and resorts along with spas and best places to eat in Bali. Seminyak however is a good surfing spot only for those who are experienced as there are strong undercurrents. There are clubs and bars and other hang out spots where you can watch the brilliant sunsets.",
          images: [
            require("../../assets/packages-images/bali/1.jpeg"),
            require("../../assets/packages-images/bali/2.jpeg"),
          ],
        },
        {
          title: "Tanah Lot",
          desc: "Tanah Lot is one of the most iconic places to visit in Bali. This temple, located on the coast, has an ancient Hindu temple on a clifftop. The setting makes it unique and scenic at the same time. Do witness this stunning sight with our Bali Packages. With waves crashing against it continuously for centuries, the cliff had eroded until there were specific preservation efforts taken to ensure that Tanah Lot did not completely disappear. During high tide, visitors are unable to cross and view the rock base but during low tide, it’s possible. Do note that people are not allowed to enter the temple grounds, but the views make up for it entirely.",
          images: [
            require("../../assets/packages-images/bali/3.jpeg"),
            require("../../assets/packages-images/bali/4.jpeg"),
          ],
        },
        {
          title: "Ubud Monkey Forest",
          desc: "Have your fill of exotica at the Ubud Monkey Forest, one of the most popular places to visit on your Bali Tour. Trek through the forest which is a natural sanctuary for grey long-tailed macaques. There are paved pathways here which lead to the Pura Dalem Agung Padangtegal or the Great Temple of Death. The dense trees prevent sunlight from entering, so there’s definitely a mysterious aura around the place. The statues and other relics look ancient thanks to the moss covering them luxuriously. Since this is a sanctuary, there are plenty of monkeys to be found here. Do be careful about wearing shiny or loose jewellery as the monkeys might take a fancy to it.",
          images: [
            require("../../assets/packages-images/bali/5.jpeg"),
            require("../../assets/packages-images/bali/6.jpeg"),
          ],
        },
        {
          title: "Bali Safari And Marine Park",
          desc: "Among the interesting places to visit in Bali is the Bali Safari and Marine Park. Animals here are allowed to roam freely and you can ride a safari bus to visit the animals. Some of the animals here are Himalayan bears, black bucks, African hippos, ostriches, baboons and lions while the biggest draw would be the Indian white tigers. The safaris give you a chance to view the animals in their natural habitat There’s also a water park and amusement park adjacent to this, so it’s a great spot for families, especially with children. There are free live animal shows and photo opportunities with some of the animals.",
          images: [
            require("../../assets/packages-images/bali/7.jpeg"),
            require("../../assets/packages-images/bali/8.jpeg"),
          ],
        },
        {
          title: "Goa Gajah",
          desc: "This is another iconic temple which is near Bedulu village. There’s much to see and explore at this exotic temple which is an archaeological site with a rich historic background. The rock walls are carved with various designs and there are fountains and bathing pools in the main grounds. Like most tourist places, there are kiosks and vendors, selling souvenirs and trinkets as you walk down the steps to the main ground. This temple has both Hindu and Buddhist influences and there’s a sense of serenity in the air which you must definitely experience on your Bali Holiday.",
          images: [
            require("../../assets/packages-images/bali/9.jpeg"),
            require("../../assets/packages-images/bali/10.jpeg"),
          ],
        },
        {
          title: "Tegallalang Rice Terraces",
          desc: "Verdant rice fields are aplenty in Ubud while the terraced rice fields of Tegallalang offer a breath-taking scene. Inhale the fresh, crisp air as you look across the stunning green views. Nearby, there are small villages, particularly Padukui village where Balinese craftsmen create some splendid work in sculpture and wood carving. You can pick up some excellent souvenirs here, although do be wary of pushy vendors.",
          images: [
            require("../../assets/packages-images/bali/11.jpeg"),
            require("../../assets/packages-images/bali/12.jpeg"),
          ],
        },
        {
          title: "Garuda Wisnu Kencana",
          desc: "Jimbaran however isn’t just a beach town with nothing else to do. It is culturally significant as it has a number of temples and historical sites from Indonesia’s past. Garuda Wisnu Kencana is one such place. A cultural park in Ungasan, a few minutes’ drive from Jimbaran’s centre, all 250 hectares of this park are postcard worthy vistas. The name is derived from a large statue of Lord Vishnu, seated on a majestic garud. The Balinese connection to Hinduism is very strong and goes back to the roots of its foundation. Also called GWK, this park has many stages where you can catch local Balinese folk dances, traditional theatre and music performances.",
          images: [
            require("../../assets/packages-images/bali/13.jpeg"),
            require("../../assets/packages-images/bali/14.jpeg"),
          ],
        },
        {
          title: "Uluwatu temple",
          desc: "Uluwatu temple overlooks the sea from its vantage point on a craggy limestone bluff. Located just outside Jimbaran and surrounded by a tropical forest, the approach to Uluwatu is guarded by mischievous macaques, who won’t hesitate to steal your stuff unless you pacify them with some fruit.",
          images: [
            require("../../assets/packages-images/bali/15.jpeg"),
            require("../../assets/packages-images/bali/16.jpeg"),
          ],
        },
        {
          title: "Mount Batur & Mount Agung",
          desc: "You must start off your Kintamani tour Bali by trekking up Mount Batur, an active volcano, to watch the sunrise paint the sky with a palette that sets it ablaze. From gentle pink, to placid purple, to fiery red and finally gentle golden. Mount Agung is a currently active volcano close to Kintamani and is a trip worth making.  Witness it spew columns of volcanic ash and spit lava all from a safe distance. You can hike up to the caldera or take a helicopter tour for a bird’s eye view.",
          images: [
            require("../../assets/packages-images/bali/17.jpeg"),
            require("../../assets/packages-images/bali/18.jpeg"),
          ],
        },
        {
          title: "Tampak Siring",
          desc: "Tirtha Empul temple also called Tampak Siring Temple or the Holy Spring Water Temple in Kintamani is home to an ancient Hindu temple carved out of stone with a natural spring considered sacred in Balinese culture the waters here are said to have healing properties.",
          images: [
            require("../../assets/packages-images/bali/19.jpeg"),
            require("../../assets/packages-images/bali/20.jpeg"),
          ],
        },
        {
          title: "Canggu",
          desc: "Canggu, pronounced Changgu, is the chilliest spot in Bali. Canggu isn’t uber luxurious or as expensive as the other resort towns in Bali such as Ubud, Seminyak or Uluwatu, which makes it perfect for less expensive, longer vacations. The best thing about Canggu is that it still retains a feel of Balinese culture, but with a tinge of modernity. Here, you can relax on the beach, attend a yoga retreat and also get a bit of work done at a shared working space like a cafe or art gallery. These factors are what make a Canggu holiday unique and all the more desirable. you can soothe your muscles with a therapeutic dip in the sea while scuba diving with docile manta rays at Manta point at Nusa Penida, a tiny island nearby,Batu Bolong beach in Canggu is a haven for surfers. Whether you’re a novice or a master of the waves, Batu Bolong beach has surf spots for everyone. Just rent a board and say ‘Cowabunga’.",
          images: [
            require("../../assets/packages-images/bali/21.jpeg"),
            require("../../assets/packages-images/bali/22.jpeg"),
          ],
        },
        {
          title: "Pura Candidasa",
          desc: "Pura Candidasa, also known as the Temple of Ten Children, or Mother Temple, is a Hindu twin temple dedicated to the goddess of rain and fertility, Hariti, and Shiva. With a lagoon and an ocean on either side, Pura Candidasa is a wonder to behold. Pay close attention to the carvings on the walls for they tell beautiful stories of Hariti, and give you a look into original Balinese mythology.",
          images: [
            require("../../assets/packages-images/bali/23.jpeg"),
            require("../../assets/packages-images/bali/24.jpeg"),
          ],
        },
        {
          title: "Kuta",
          desc: "If ever there is a place which promises 24 hours of fun unlimited, Kuta it is! Situated in the south of Bali, this beach town boasts of something for everyone! Kuta’s long sandy beaches stretch till the eye can see and it attracts surfers from all over the world. A bustling street runs along the beach, dotted with restaurants, shops, bars and malls offering myriad shopping, drinking and dining choices. In the evenings enjoy a glorious sunset on Kuta beach and as day turns to night, you can head to one of the many nightclubs to party the night away. Another attraction in Kuta is Vihara Dharmayana temple, Any Kuta travel guide will tell you that as you step inside this over-200-year-old Buddhist Temple, a sense of calmness comes over you. Its eastern style architecture, huge murals and distinct red walls and pillars wrapped by fiery dragons gives a glimpse of Kuta’s rich cultural heritage.",
          images: [
            require("../../assets/packages-images/bali/25.jpeg"),
            require("../../assets/packages-images/bali/26.jpeg"),
          ],
        },
        {
          title: "Denpasar",
          desc: "Denpasar, the capital of Bali, lies in the southern part of the island. It is a major hub and acts like a gateway to many tourist destinations in Bali. Explore the religious side of Denpasar with a trip to the Jagatnatha Temple, the largest temple in Denpasar devoted to the Hindu God, Sanghyang Widi. It’s a visually striking temple with a towering Padmasana. The walls have well-preserved carvings and stone motifs depicting traditional stories from the Ramayana. You can visit ancient temples or check out the exquisite artworks displayed in the museums. If you are with kids, Denpasar also has fun things for you to do. If you are an adrenaline junkie, Denpasar travel gives you your vacay fix at Shark Island. You can take a 3-hour eco tour here, where you not only swim with sharks but feed them too. Take your kids along and have a blast in this safe and guarded environment. The turtle park on Serangan Island can be reached easily in about half an hour from Denpasar by road. It is an important nesting ground for green sea turtles where you can watch and learn about turtle breeding.",
          images: [
            require("../../assets/packages-images/bali/27.jpeg"),
            require("../../assets/packages-images/bali/28.jpeg"),
          ],
        },
        {
          title: "Ubud",
          desc: "The laid-back town of Ubud, located in the highlands, is quite literally the heart of Bali. Ubud tourism pulsates with a perfect fusion of art, religion and verdant greenery. Surrounded by lush jungles, this is an ideal place to rejuvenate your spirits. Suffused with Balinese culture, Ubud Bali is home to many temples, spas, restaurants and museums. The streets are vibrant with colourful art, crafts and souvenirs. The hilly jungles are alive with tropical birds and monkeys. The town is dotted with lush green paddy fields and palm trees. Your Ubud tour will be complete only when you shop in this paradise, experience religion in the ancient temples and unwind in this peaceful retreat where serenity and tranquillity will be your true companions.",
          images: [
            require("../../assets/packages-images/bali/29.jpeg"),
            require("../../assets/packages-images/bali/30.jpeg"),
          ],
        },
        {
          title: "Lovina Beach",
          desc: "Kicking Back Relaxing and Falling in Love with Lovina Beach. An unobstructed view to the endless sky, saying hello to the sun that doesn’t seem as far away as it does in the city... Going to a beach is an out-of-the-world experience every single time, the correct answer is Lovina Beach in Bali. You can also trek to Bali’s largest and only Buddhist Temple, Meditate in the lush greenery and quiet of the Brahma Vihara Arama temple monastery near Lovina beach. You can book a meditation class, or even just taking a stroll in its sprawling gardens and drinking the beautiful scenery is therapeutic enough.\n\nAnother attraction is to walk it up to GitGit Waterfalls, a paradise abodes the hills of Bali, Gitgit Waterfalls are only a 40-minute trip away from Lovina Beach. Its pool is perfect for a leisurely swim. And if you have the time, you can also enjoy many of the other nearby (and also less crowded) waterfall. ",
          images: [
            require("../../assets/packages-images/bali/31.jpeg"),
            require("../../assets/packages-images/bali/32.jpeg"),
          ],
        },
        {
          title: "Padangbai Ubud",
          desc: "Swim With Fishes in The Deep Blue Sea at Padangbai, Explore the wonderful bustling lives of underwater creatures like fishes and turtles, and even sharks with scuba diving at Padangbai. If you can’t swim or haven’t ever been scuba diving, don’t worry. Diving at Padangbai is very beginner-friendly. It is, after all, the best diving location in all of Bali. There are more than enough businesses that can provide you with a short diving course before you can get started.",
          images: [
            require("../../assets/packages-images/bali/33.png"),
            require("../../assets/packages-images/bali/34.jpeg"),
          ],
        },
        {
          title: "Pura Lempuyang",
          desc: "Pura Lempuyang is one of the most popular and sacred temples on the island of Bali. The ‘Gates of Heaven’ are on the premises of one of the seven temples that make the Pura Lempuyang. Looking through it makes you feel like you’re floating in the sky, and it gives you a peek into Mount Agung, an active volcano. But remember to carry a sarong and only wear comfortable shoes. The temple is decorated with stairs and it’s in your best interest to be comfortable so that you can enjoy every aspect the holy grounds have to offer.",
          images: [
            require("../../assets/packages-images/bali/35.jpeg"),
            require("../../assets/packages-images/bali/36.jpeg"),
          ],
        },
      ],
      thumbnail: [require("../../assets/packages-images/bali/1.jpeg")],
    },
    {
      placeName: "Thailand",
      thumbnail: [require("../../assets/packages-images/thailand/1.jpeg")],
      mainDesc:
        "Thailand is a country of many contrasting facets, with glorious beaches as well as forests, jungles and mountains. Whether you want to swim, sunbathe or explore the wildlife, a family holiday in Thailand will never be dull. From Bangkok and Pattaya to Phuket and Krabi; from beaches and temples to shopping, food and spas; Thailand is adorned with a stunning coastline, magnificent hills and spectacular sceneries.",
      content: [
        {
          title: "Bangkok",
          desc: "Thailand's capital city is nothing short of exhilarating. The city offers a hearty mix of big-city bustle and modern sights, such as the Jim Thompson House, alongside ancient attractions, including Wat Arun, Wat Pho and the Grand Palace. Just don't forget to pack modest clothing for visits to sacred sites, or you won't be admitted. While here, be sure to visit the Chatuchak Weekend Market – one of the largest street markets in the world – and eat at some of Bangkok's more than 300,000 street food stalls (some Michelin starred). At night, take in the skyline by exploring the rooftop bar scene.",
          images: [
            require("../../assets/packages-images/thailand/1.jpeg"),
            require("../../assets/packages-images/thailand/2.jpeg"),
          ],
        },
        {
          title: "Pattaya",
          desc: "Pattaya is a city on Thailand’s eastern Gulf coast, a visit to Pattaya is a wonderful way to explore the beaches along the Gulf of Thailand. Relaxed and family-friendly Jomtien Beach is a hot spot for watersports and seaside massages. The giant an 18m-tall golden Buddha of Wat Khao Phra Bat keeps watch over the city, and the wooden Wang Boran Sanctuary of Truth pays homage to Buddhist and Hindu art and architecture. A quiet fishing village as recently as the 1960s, it’s now lined with resort hotels, high-rise condos, shopping malls, cabaret bars and 24-hour clubs.",
          images: [
            require("../../assets/packages-images/thailand/3.jpeg"),
            require("../../assets/packages-images/thailand/4.jpeg"),
          ],
        },
        {
          title: "Phuket",
          desc: "Phuket is the largest island in Thailand and also the most popular with travelers. The island draws visitors in with its many palm-fringed beaches and relatively low travel costs, including everything from food to hotels. Hit up highly regarded Patong Beach, party the night away in the Patong district or head inland to Phuket Old Town to experience more local culture and find cheaper lodging options. Complete your once-in-a-lifetime trip by taking a long-tail boat tour to other islands in the area to swim, snorkel or scuba dive.",
          images: [
            require("../../assets/packages-images/thailand/5.jpeg"),
            require("../../assets/packages-images/thailand/6.jpeg"),
          ],
        },
        {
          title: "Krabi",
          desc: "The top tourist destinations in Thailand include Krabi as well. The Krabi province, which comprises of more than 200 islands, has a substantial area of land set aside as national parks. It has beautiful beaches and a coastline with sheer, vertical limestone cliffs that are a favourite among climbers.",
          images: [
            require("../../assets/packages-images/thailand/7.jpeg"),
            require("../../assets/packages-images/thailand/8.jpeg"),
          ],
        },
        {
          title: "Chiang Mai",
          desc: "Chiang Mai in northern Thailand is a welcome reprieve from the commotion of Bangkok. Here, you'll be treated to beautiful, mountainous landscapes and an Old City full of historical temples, such as Wat Phra Singh and Wat Chedi Luang. Venture outside of the city to Doi Suthep temple for sweeping views of the city below. Night markets are also a must-do in Chiang Mai, so pencil in time for the Chiang Mai Night Bazaar (one of Thailand's oldest and most well-known night bazaars). And no matter what, leave time for a visit to Elephant Nature Park for some rest and relaxation with Thailand's gentle giants.",
          images: [
            require("../../assets/packages-images/thailand/9.jpeg"),
            require("../../assets/packages-images/thailand/10.jpeg"),
          ],
        },
        {
          title: "Ayutthaya",
          desc: "Ayutthaya, the second capital of Old Siam, offers a fascinating peek into the glory days of a once mighty Siamese Kingdom that dates back to the 14th century. Once one of Asia’s most powerful cities, Ayutthaya was a flourishing center for regional trade where art and culture merged and by the early 18th century, was one of the world’s largest cities with an estimated one million inhabitants. Exploring Ayutthaya’s crumbling yet magnificently restored Buddhist temples and statues, royal palaces, monasteries, towers and chedis, displaying an eclectic array of architectural styles. Stunning highlights feature a 39-foot-long reclining Buddha and iconic Buddha head entwined by overgrown tree roots.",
          images: [
            require("../../assets/packages-images/thailand/11.jpeg"),
            require("../../assets/packages-images/thailand/12.jpeg"),
          ],
        },
        {
          title: "Ko Samui",
          desc: "Home to five-star resorts, amazing nightlife and some of Thailand's most beautiful beaches, the island of Ko Samui has something for everyone. When you're not lounging on the white sand, pamper yourself with a luxurious spa treatment, visit an elephant sanctuary or explore ancient temples like Wat Plai Laem and Wat Phra Yai, also known as the Big Buddha Temple. Save time in your schedule for a daytrip to nearby Ang Thong Marine Park, a 42-island archipelago that is accessible via speedboat. Here, travelers can kayak, snorkel and scuba dive.",
          images: [
            require("../../assets/packages-images/thailand/13.jpeg"),
            require("../../assets/packages-images/thailand/14.jpeg"),
          ],
        },
        {
          title: "Phang Nga",
          desc: "Although the region was devastated by the 2004 Indian Ocean tsunami, Phang Nga has recovered and rebuilt. During the cool (though still relatively hot), dry season (from November to February), tourists descend upon the province to check out protected areas like Mu Ko Surin National Park, one of the best diving sites in Thailand. Hiking and snorkeling are also excellent ways to explore while admiring southern Thailand's natural beauty. Before leaving, don't forget to save time for a visit to Ao Phang-Nga National Park, famous for its caves, emerald green waters and the picturesque James Bond Island.",
          images: [
            require("../../assets/packages-images/thailand/15.jpeg"),
            require("../../assets/packages-images/thailand/16.jpeg"),
          ],
        },
        {
          title: "Railay Beach",
          desc: "Railay Beach features four stunning stretches of sand to sink your toes into. Railay West and Phra Nang Beach are two of the peninsula's most popular beach spots, offering luxury resorts, soft sand and jaw-dropping views of limestone cliffs. Tonsai Beach is more laid-back and budget-friendly thanks to its quieter, more removed location. Meanwhile, Railay East, while not great for swimming, is where you'll find some of the area's best nightlife venues. Regardless of which beaches you choose to visit, you'll have access to some of Thailand's best rock climbing locations, plus various walking paths and coral reefs.",
          images: [
            require("../../assets/packages-images/thailand/17.jpeg"),
            require("../../assets/packages-images/thailand/18.jpeg"),
          ],
        },
        {
          title: "Trang",
          desc: "Trang's secluded beaches and stunning islands have made it an up-and-coming travel destination. The dramatic surrounding landscapes (think: lush jungles and limestone mountains) contrast beautifully against the beaches' white sands and crystal-clear waters. You can spend days island-hopping to see and do all that Trang has to offer. Top activities you may enjoy include snorkeling around Ko Kradan, swimming to a hidden beach cave on Ko Muk and wandering through Thung Khai Botanical Garden. And after you've gotten your fill of sun and sand, head to one of Trang's 10 districts to immerse yourself in Thai culture.",
          images: [
            require("../../assets/packages-images/thailand/19.jpeg"),
            require("../../assets/packages-images/thailand/20.jpeg"),
          ],
        },
        {
          title: "Ko Phi Phi",
          desc: "Ko Phi Phi features some of Thailand's most popular beaches. Phi Phi Don, the larger of the two main islands, is known for its lively party scene, hidden coves and deep coral reefs and gardens that are ideal for snorkeling and diving. Meanwhile, the smaller, uninhabited Phi Phi Leh is where you'll find verdant cliffs surrounding Maya Bay's beautiful beaches, which you may recognize from the Leonardo DiCaprio movie The Beach. The best time to visit is between November and April, though be prepared for hordes of other visitors during this time.",
          images: [
            require("../../assets/packages-images/thailand/21.jpeg"),
            require("../../assets/packages-images/thailand/22.jpeg"),
          ],
        },
        {
          title: "Chiang Rai",
          desc: "Situated in northern Thailand, there are countless reasons to visit Chiang Rai. Wat Rong Suea Ten (The Blue Temple) and Wat Phra Kaeo (Temple of the Emerald Buddha) are two of the city's most popular cultural landmarks, but travelers should also save time for the incredible Wat Rong Khun, Chiang Rai's iconic all-white temple. After exploring Chiang Rai's architectural wonders, enjoy a boat tour of the Mae Kok River, go shopping at the Chiang Rai Night Bazaar or explore the Baan Dam Museum, a collection of buildings created by artist Thawan Duchanee.",
          images: [
            require("../../assets/packages-images/thailand/23.jpeg"),
            require("../../assets/packages-images/thailand/24.jpeg"),
          ],
        },
        {
          title: "Kanchanaburi",
          desc: "Thailand's third-largest province captivates travelers with its natural beauty. Its diverse landscape – which features everything from seven-tiered waterfalls, rivers and mountains to Neolithic caves and national parks – is ideal for outdoor pursuits, including hiking and rafting. But a trip here wouldn't be complete without visiting some of the area's World War II sites, including the notorious Bridge over the River Kwai. Start your history lesson at the Thailand-Burma Railway Centre, an interactive museum that tells the tragic story of how the Thailand-Burma Railway was built. Then, stop by the JEATH War Museum to see a replica of a prisoners-of-war camp.",
          images: [
            require("../../assets/packages-images/thailand/25.jpeg"),
            require("../../assets/packages-images/thailand/26.jpeg"),
          ],
        },
        {
          desc: "Khao Sok National ParkEven if you don't consider yourself an outdoorsy person, a trip to Khao Sok National Park is sure to take your breath away. Home to limestone cliffs, awe-inspiring waterfalls and the world's oldest evergreen rainforest, Khao Sok is an incredibly bio-diverse area. Here, you'll find roughly 200 kinds of flora and many different animal species, including tigers, elephants and Southern pig-tailed macaques (monkeys). The park sees a lot of rainfall year-round, so for the best chance of staying dry, time your visit during the dry season, which runs from December to April.",
          title: "Khao Sok National Park ",
          images: [
            require("../../assets/packages-images/thailand/27.jpeg"),
            require("../../assets/packages-images/thailand/28.jpeg"),
          ],
        },
        {
          title: "Khao Yai National Park",
          desc: "Khao Yai National Park boasts a number of impressive superlatives: It's the oldest and most-visited national park in Thailand, the country's third-largest national park and one of mainland Asia's largest intact monsoon forests. Plus, this stunning national park is a UNESCO World Heritage Site and features more than 30 miles of hiking trails, some of which lead to breathtaking waterfalls. Keep in mind, though that guides are required for most of the trails. While exploring, you may spot some of Khao Yai's wild residents, including elephants, sun bears and several species of hornbills.",
          images: [
            require("../../assets/packages-images/thailand/29.jpeg"),
            require("../../assets/packages-images/thailand/30.jpeg"),
          ],
        },
        {
          title: "Hat Yai",
          desc: "Located in southern Thailand near the Malaysian border, Hat Yai is a popular stopover for tourists traveling between the two countries. This cosmopolitan city is a premier shopping hub, boasting several malls, night bazaars and a unique floating market, where travelers can purchase local delicacies and goods from vendors stationed in small boats along a canal. When you're not bargaining for the best price, check out the impressive Phra Maha Chedi Tripob Trimongkol, a temple constructed entirely out of stainless steel. Another can't-miss tourist attraction in Hat Yai is the towering statue Phra Buddha Mongkol Maharaj, which sits within Hat Yai Municipal Park.",
          images: [
            require("../../assets/packages-images/thailand/31.jpeg"),
            require("../../assets/packages-images/thailand/32.jpeg"),
          ],
        },
      ],
      conclusion: "",
    },
  ],
};

const PackagesSlice = createSlice({
  initialState,
  name: "packages",
  reducers: {},
});

export const usePackageSlice = () =>
  useAppSelector((state) => {
    return state.packages;
  });
export const PackageReducer = PackagesSlice.reducer;
// export const {}  =PackagesSlice.actions
