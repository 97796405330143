import React, { FC } from "react";
import { Container } from "../container";
import { useNavigate } from "react-router-dom";

export interface Ads3Props {
  background: string;
}

export const Ads3: FC<Ads3Props> = ({ background }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
        className="rounded-lg"
      >
        <div className="w-full h-full pt-[100px] pb-[70px] px-[50px] rounded-lg bg-opacity-30 bg-primary-500">
          <div className="xl:w-[60%]">
            <p className="text-white">Plan Your Corporate Travel with US.</p>
            <h6 className="text-3xl text-white mb-3">
              Your Vision, Our Expertise – Incredible travel Experience
            </h6>
            <button
              className="bg-primary-600 p-3 rounded-md"
              onClick={() => navigate("/corp-booking")}
            >
              <span className="text-md text-white uppercase">
                Corporate Booking
              </span>
            </button>{" "}
          </div>
        </div>
      </div>
    </Container>
  );
};
