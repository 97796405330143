import React, { FC } from "react";

interface Ads2Props {
     label: string;
     subTitle: string;
     desc: string;
     background: string;
}

export const Ads2: FC<Ads2Props> = ({ desc, label, subTitle, background }) => {
     return (
          <div className="my-10">
               <div
                    className="w-[95%] mx-auto"
                    style={{
                         backgroundImage: `url(${background})`,
                         backgroundPosition: "center",
                         backgroundSize: "cover",
                         // height: "300px",
                    }}
               >
                    <div className="bg-gray-900 bg-opacity-50 h-full w-full xl:p-[100px] p-10 flex items-center gap-10 flex-wrap">
                         <div className="w-[60%]">
                              <p className="text-white font-light  font-sans">{subTitle}</p>
                              <h6 className="text-3xl mb-5 font-josefine leading-[45px] font-[400px] text-white capitalize">
                                   {label}
                              </h6>
                              <p className="text-white leading-[26px] text-sm">{desc}</p>
                         </div>
                    </div>
               </div>
          </div>
     );
};
