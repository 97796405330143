import React from "react";
import PlaceCard from "./PlaceCard";
// import { NavBar } from "../../layout";
// import { NavBar } from '../../layout/nav/index';
import { MainLayout } from "../../layout/main/index";

interface Place {
  id: number;
  img: string;
  title: string;
  location: string;
  description: string;
  price: number;
  type: string;
}

interface PlacesProps {
  title?: string;
  handleOrderPopup: () => void;
  useSlider?: boolean;
  popUp?: boolean;
  hidePlaceCardBody?: boolean;
  placeimg?: boolean;
}

const Places: React.FC<PlacesProps> = ({
  title,
  handleOrderPopup,
  useSlider = false,
  popUp = false,
  // hidePlaceCardBody = false,
  placeimg = true,
}) => {
  const PlacesData: Place[] = [
    {
      id: 1,
      img: "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-4.jpg",
      title: "Bali",
      location: "Beach",
      description:
        "One place for all your boating needs. We offer dock slips, dry dock space, boat launch, rentals, new and used boat sales, custom built docks, fuel, service/mechanic shop and restaurant.",
      price: 267000,
      type: "Book Now",
    },
    {
      id: 2,
      img: "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-1.jpg",
      title: "Singapor",
      location: "Magical Singapor",
      description:
        "Thailand's beauty is diverse and multifaceted, encompassing a vast array of landscapes, cultures, and wildlife. From the rich tapestry of natural wonders.",
      price: 49000,
      type: "Book Now ",
    },
    {
      id: 3,
      img: "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-3.jpg",
      title: "Dubai",
      location: "UAE",
      description:
        "This tropical paradise, located in the heart of the Indian Ocean, boasts some of the most breathtaking and diverse underwater landscapes in the world. With its crystal-clear waters, vibrant coral reefs, and an abundance of marine life, the Maldives is a dream destination for divers",
      price: 13200,
      type: "Book Now",
    },
    {
      id: 4,
      img: "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-4.jpg",
      title: "Switzerland",
      location: "Switzerland",
      description:
        "Switzerland's beauty is defined by its stunning natural landscapes, which include majestic mountains, crystal-clear lakes, lush green valleys, and picturesque villages. The Swiss Alps dominate much of the country's scenery, offering breathtaking vistas, snow-capped peaks, and opportunities for outdoor activities such as hiking, skiing, and mountaineering",
      price: 267000,
      type: "Book Now",
    },
    {
      id: 5,
      img: "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-3.jpg",
      title: "Turkey",
      location: "Turkey",
      description:
        "Best thing to do in Los Angeles as a visitor? That’s easy! Take the best tour of Hollywood and Beverly Hills with us! Not only do we offer the very best in tours in and around Hollywood during the day, but we also offer incredible night excursions. After all, the stars come out at night? Why not put yourself where the action is with Star Track Tours! We’ve taken the celebrity star tours experience of yesteryear and revamped it to be more exciting, star-studded and eventful.",
      price: 490000,
      type: "Book Now",
    },
    {
      id: 6,
      img: "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-2.jpg",
      title: "Singapore",
      location: "Singapore",
      description:
        "Las Vegas is an internationally renowned major resort city, known primarily for its gambling, shopping, fine dining, entertainment, and nightlife, with most venues centered on downtown Las Vegas and more to the Las Vegas Strip just outside city limit",
      price: 500000,
      type: "Book Now",
    },
  ];

  return (
    <>
      <MainLayout>
        <div
          className="dark:bg-gray-900 dark:text-white bg-gray-50 py-10"
          style={{ paddingLeft: "33px" }}
        >
          <section data-aos="fade-up" className="container">
            <h1 className="my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
              {title ? title : "Popular Destinations"}
            </h1>
            {useSlider && (
              <div className="grid grid-cols-1 gap-4">
                {/* <Slider {...settings}> */}
                {PlacesData.map((item, index) => (
                  <PlaceCard
                    popUp={false}
                    handleOrderPopup={handleOrderPopup}
                    key={index}
                    {...item} // hidePlaceCardBody={hidePlaceCardBody}
                  />
                ))}
                {/* </Slider> */}
              </div>
            )}

            {!useSlider && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {PlacesData.map((item, index) => (
                  <PlaceCard
                    handleOrderPopup={handleOrderPopup}
                    key={index}
                    popUp={popUp}
                    {...item}
                  />
                ))}
              </div>
            )}
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Places;

// interface SliderSettings {
//      dots: boolean;
//      infinite: boolean;
//      speed: number;
//      slidesToShow: number;
//      slidesToScroll: number;
//      autoplaySpeed: number;
//      cssEase: string;
//      pauseOnHover: boolean;
//      pauseOnFocus: boolean;
//      responsive: ResponsiveSettings[];
// }

// interface ResponsiveSettings {
//      breakpoint: number;
//      settings: {
//           slidesToShow: number;
//           slidesToScroll: number;
//           infinite: boolean;
//      };
// }
