import React from "react";
import { MainLayout } from "../../layout";
import { ContactForm } from "../../component";

export const CorporateBookingPage = () => {
  const venuePoints = [
    {
      label: "We always provide itemized costs . This allows you to focus on your day-to-day responsibilities, safe in the knowledge that every part of your travel incentive is taken care of. Our unrivalled travel incentives are proven to motivate  teams and drive fantastic results",
      point:null,
    },
    {
      label: "Enjoy Hassle-Free Management",
      point: null,
    },
    {
      label: null,
      point: "Creating extraordinary incentive travel.",
    },
    {
      label: null,
      point: "Integrate your existing incentive structure",
    },
    {
      label: "",
      point:
        "Manage the full end-to-end experience independently",
    },
    {
      label: "",
      point:
          "Hassle free meticulously planned trips",
    },
    {
      label: "",
      point:
          "Organize & execute each step—always keeping your brand and image front of mind. ",
    },
  ];

  const servicesPoint = [
    {
      label: "",
      point:
        "Our Experts Create Outstanding incentive  travel itineraries."
      ,
    },
    {
      label: "",
      point:
        "We Create Packages that fits with your  company’s goals and desired results.",
    },
    {
      label: "",
      point:
        "Our Dedicated Communications team is available throughout, while our in-house creative team can assist in short video production too ",
    },
  ];

  return (
    <MainLayout>
      <div
        style={{
          backgroundImage:
            "url(https://images.pexels.com/photos/3573383/pexels-photo-3573383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="h-[60vh] bg-gray-900 w-full bg-opacity-50 flex justify-center items-center">
          <h6 className="text-4xl font-semibold text-white capitalize">
            Corporate booking
          </h6>
        </div>
      </div>
      <div className="grid xl:w-[80%] xl:px-0 px-5 mx-auto my-10 py-10 gap-10 grid-cols-12">
        <div className="xl:col-span-8 col-span-12">
          <h3 className="text-3xl font-semibold text-center capitalize border-b-4 pb-3 rounded-md w-[30%] mx-auto border-primary-500">
            What we  <span className="text-primary-500">provide</span>
          </h3>
          <div className="grid  gap-10 grid-cols-1 items-center justify-center my-10 2 md:grid-cols-1 sm:grid-cols-1">
            {venuePoints.map(({ label, point }, i) => (
              <div className="flex xl:flex-row flex-col md:flex-row group">
                {label !== null && label !== '' && <span className="text-primary-500 text-xl font-semibold font-josefine">{label}</span>}
                {point !== null && point !== '' && <h6 className="text-md text-gray-500">{point}</h6>}
              </div>
            ))}
          </div>
          <div className="mb-20 py-10">
            <h3 className="text-3xl font-semibold text-center capitalize border-b-4 pb-3 rounded-md w-[30%] mx-auto border-primary-500">
              Your Vision, Our Expertise, <span className="text-primary-500">
           Incredible Incentive Travel</span>
            </h3>
            <div className="grid gap-10 grid-cols-1 items-center justify-center my-10 2 md:grid-cols-1 sm:grid-cols-1">
              {servicesPoint.map(({ label, point }, i) => (
                <div className="flex xl:flex-row flex-col md:flex-row group">

                  <div className="group-hover:bg-primary-50 transition-all duration-300 p-3 rounded-md">
                    {label !== null && label !== '' && <span className="text-primary-500 text-xl font-semibold font-josefine">{label}</span>}
                    {point !== null && point !== '' && <h6 className="text-md text-gray-500">{point}</h6>}


                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="xl:col-span-4 col-span-4">
          <div className="mt-32">
            <ContactForm formLabel="Booking for corporate" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
