import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";
import moment from "moment";

interface LayoutAppProps {
  contactModel: boolean;
  contactForm: {
    name: string;
    email: string;
    place: string | null;
    date: string;
    mobile: string;
    duration: string;
    budget: number;
    people: number;
    adult: number;
    children: number;
    enquiry: string;
  };
}

const initialState: LayoutAppProps = {
  contactModel: false,
  contactForm: {
    date: moment().format("YYYY-MM-DD"),
    name: "",
    email: "",
    place: null,
    adult: 0,
    budget: 0,
    children: 0,
    duration: "",
    enquiry: "",
    mobile: "",
    people: 0,
  },
};

export const LayoutApp = createSlice({
  initialState,
  name: "layout",
  reducers: {
    toggleContactModal: (state, action: PayloadAction<boolean>) => {
      state.contactModel = action.payload;
    },
    handleName: (state, action: PayloadAction<string>) => {
      state.contactForm.name = action.payload;
    },
    handleDate: (state, action: PayloadAction<string>) => {
      state.contactForm.date = action.payload;
    },
    handlePlace: (state, action: PayloadAction<string>) => {
      state.contactForm.place = action.payload;
    },
    handleEmail: (state, action: PayloadAction<string>) => {
      state.contactForm.email = action.payload;
    },
    handleMobile: (state, action: PayloadAction<string>) => {
      state.contactForm.mobile = action.payload;
    },
    handleDuration: (state, action: PayloadAction<string>) => {
      state.contactForm.duration = action.payload;
    },
    handleBudget: (state, action: PayloadAction<string>) => {
      state.contactForm.budget = parseInt(action.payload);
    },
    handlePeople: (state, action: PayloadAction<string>) => {
      state.contactForm.people = parseInt(action.payload);
    },
    handleAdults: (state, action: PayloadAction<string>) => {
      state.contactForm.adult = parseInt(action.payload);
    },
    handleChildren: (state, action: PayloadAction<string>) => {
      state.contactForm.children = parseInt(action.payload);
    },
    handleEnquiry: (state, action: PayloadAction<string>) => {
      state.contactForm.enquiry = action.payload;
    },
  },
});

export const useLayoutApp = () =>
  useAppSelector((state) => {
    return state.layout;
  });
export const LayoutReducer = LayoutApp.reducer;
export const {
  toggleContactModal,
  handleDate,
  handleName,
  handlePlace,
  handleEmail,
  handleAdults,
  handleBudget,
  handleChildren,
  handleDuration,
  handleEnquiry,
  handleMobile,
  handlePeople,
} = LayoutApp.actions;
