import React, { FC } from "react";
import {
  handleAdults,
  handleBudget,
  handleChildren,
  handleDate,
  handleDuration,
  handleEmail,
  handleEnquiry,
  handleMobile,
  handleName,
  handlePeople,
  handlePlace,
  toggleContactModal,
  useDestinationSlice,
  useLayoutApp,
} from "../../redux/app";
import { useAppDispatch } from "../../redux/hooks";
import { toast } from "react-toastify";
import emailJs from "@emailjs/browser";

interface ContactFormProps {
  formLabel?: string;
  showCancelBtn?: boolean;
}

export const ContactForm: FC<ContactFormProps> = ({
  formLabel,
  showCancelBtn,
}) => {
  const state = useDestinationSlice();
  const { contactForm } = useLayoutApp();
  const dispatch = useAppDispatch();
  const SendEnquiry = async () => {
    if (!contactForm.date || !contactForm.name || !contactForm.place) {
      toast.error("fill up all the fields");
    } else {
      emailJs.init({
        publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
        limitRate: {
          id: "the_valley",
          throttle: 10000,
        },
      });
      const response = await emailJs.send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID as string,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID as string,
        {
          name: contactForm.name,
          place: contactForm.place,
          date: contactForm.date,
          email: contactForm.email,
        }
      );
      if (response.status === 200) {
        toast.success(
          `We have captured your response The Valley Team will be reach you soon`
        );
        dispatch(handleEmail(""));
        dispatch(handleName(""));
      } else {
        toast.error("Failed to send email please try again!");
      }
    }
  };
  return (
    <div className="relative p-3 flex-auto bg-white rounded-lg">
      {formLabel?.length !== 0 && (
        <h6 className="text-2xl font-semibold capitalize">{formLabel}</h6>
      )}
      <div className="gap-3 flex flex-col">
        <div>
          <label htmlFor="name" className="text-sm text-gray-500">
            Full Name
          </label>
          <input
            value={contactForm.name}
            onChange={(event) => dispatch(handleName(event.target.value))}
            type="text"
            id="name"
            name="name"
            className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
            placeholder="Your name"
          />
        </div>
        <div className="flex gap-3 items-center">
          <div className="flex-1">
            <label htmlFor="name" className="text-sm text-gray-500">
              Your Email Address
            </label>
            <input
              value={contactForm.email}
              onChange={(event) => dispatch(handleEmail(event.target.value))}
              type="email"
              id="email"
              name="email"
              className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
              placeholder="abc@mail.com"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="mobile" className="text-sm text-gray-500">
              Your Mobile Number
            </label>
            <input
              value={contactForm.mobile}
              onChange={(event) => dispatch(handleMobile(event.target.value))}
              type="text"
              id="mobile"
              name="mobile"
              className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
            />
          </div>
        </div>
        <div className="flex gap-3 items-center">
          <div className="flex-1">
            <label htmlFor="duration" className="text-sm text-gray-500">
              Duration
            </label>
            <input
              value={contactForm.duration}
              onChange={(event) => dispatch(handleDuration(event.target.value))}
              type="number"
              id="duration"
              name="duration"
              className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
              placeholder="e.g. 5 days"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="place" className="text-sm text-gray-500">
              Select Destination
            </label>
            <select
              value={contactForm.place as string}
              onChange={(event) => dispatch(handlePlace(event.target.value))}
              name="place"
              id="place"
              className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
            >
              {state.destination.map(({ categoryName }) => (
                <option
                  key={categoryName}
                  className="capitalize"
                  value={categoryName}
                >
                  {categoryName}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1">
            <label htmlFor="date" className="text-sm text-gray-500">
              Select date of tour
            </label>
            <input
              value={contactForm.date}
              onChange={(e) => dispatch(handleDate(e.target.value))}
              type="date"
              id="date"
              name="date"
              className="border-2 w-full focus:outline-none focus:border-primary-500 uppercase p-2  transition-all text-sm"
            />
          </div>
        </div>
        <div>
          <label htmlFor="budget" className="text-sm text-gray-500">
            Your Budget
          </label>
          <input
            value={contactForm.budget}
            onChange={(e) => dispatch(handleBudget(e.target.value))}
            type="number"
            placeholder="20,000"
            id="budget"
            name="budget"
            className="border-2 w-full focus:outline-none focus:border-primary-500 uppercase p-2  transition-all text-sm"
          />
        </div>
        <div className="flex items-center gap-3">
          <div className="flex-1">
            <label htmlFor="people" className="text-sm text-gray-500">
              No. of people
            </label>
            <input
              value={contactForm.people}
              onChange={(e) => dispatch(handlePeople(e.target.value))}
              type="people"
              placeholder="2"
              id="people"
              name="people"
              className="border-2 w-full focus:outline-none focus:border-primary-500 uppercase p-2  transition-all text-sm"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="adult" className="text-sm text-gray-500">
              No. of Adults
            </label>
            <input
              value={contactForm.adult}
              onChange={(e) => dispatch(handleAdults(e.target.value))}
              type="adult"
              placeholder="2"
              id="adult"
              name="adult"
              className="border-2 w-full focus:outline-none focus:border-primary-500 uppercase p-2  transition-all text-sm"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="children" className="text-sm text-gray-500">
              No. of Children
            </label>
            <input
              value={contactForm.children}
              onChange={(e) => dispatch(handleChildren(e.target.value))}
              type="children"
              placeholder="2"
              id="children"
              name="children"
              className="border-2 w-full focus:outline-none focus:border-primary-500 uppercase p-2  transition-all text-sm"
            />
          </div>
        </div>
        <div>
          <label htmlFor="enquiry" className="text-sm text-gray-500">
            Enquiry
          </label>
          <textarea
            className="border-2 w-full focus:outline-none focus:border-primary-500 uppercase p-2  transition-all text-sm"
            name="enquiry"
            value={contactForm.enquiry}
            onChange={(event) => {
              dispatch(handleEnquiry(event.target.value));
            }}
            rows={5}
            id="enquiry"
          ></textarea>
        </div>
        <div className="flex items-center gap-3">
          <input
            type="checkbox"
            id="t&c"
            className="w-5 h-5 bg-black checked:bg-primary-500"
          />
          <label htmlFor="t&c" className="select-none">
            I agree with{" "}
            <span className="underline text-primary-500 cursor-pointer">
              Terms of services
            </span>{" "}
            and{" "}
            <span className="underline text-primary-500 cursor-pointer">
              Privacy Statement
            </span>
          </label>
        </div>
      </div>
      <div className="flex justify-end mt-3">
        {showCancelBtn && (
          <button
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => dispatch(toggleContactModal(false))}
          >
            Cancel
          </button>
        )}
        <button
          className="bg-primary-500 text-white active:bg-primary-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
          type="button"
          onClick={SendEnquiry}
        >
          Send Enquiry
        </button>
      </div>
    </div>
  );
};
