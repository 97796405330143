import React from "react";
import { Wrapper } from "./wrapper";
import AppRoutes from "./routes";

export default function App() {
     return (
          <Wrapper>
               <AppRoutes />
          </Wrapper>
     );
}
