import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "aos/dist/aos.css";
import App from "./App";
import Aos from "aos";

Aos.init({
     duration: 2000,
     debounceDelay: 59,
     once: false,
     mirror: true,
     startEvent: "DOMContentLoaded",
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
     <React.StrictMode>
          <App />
     </React.StrictMode>
);
