import React from "react";
// import { Container } from "../../component/container";
import { useDestinationSlice } from "../../redux/app";

export const Footer = () => {
     const state = useDestinationSlice();
     return (
          <div className=" relative overflow-hidden">
               <video autoPlay loop muted className="right-0 top-0 h-[500px] overflow-hidden w-full object-cover z-10">
                    <source src={"/footer.mp4"} type="video/mp4" />
               </video>

               <div
                    className="xl:flex gap-10 flex-wrap items-center"
                    style={{
                         position: "absolute",
                         top: "16%",

                         width: "100%",
                         height: "77%",
                         padding: "5%",
                         backgroundImage:
                              "url(http://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/footer-bg.png)",
                    }}
               >
                    <div className="xl:w-[40%] w-half">
                         <img
                              src={
                                   "https://www.travtech.io/assets/travvellis-logobg-rm.png"
                              }
                              alt=""
                         />
                         <p className="text-base font-light" style={{ marginLeft: "6px" }}>
                              Traveling is not just about seeing new places, it's about experiencing new cultures and
                              making unforgettable memories."
                         </p>
                    </div>
                    <div className="flex xl:items-start justify-center flex-wrap items-start w-full flex-1">
                         <div className="flex-1">
                              <p className="text-base font-josefine" style={{ marginLeft: "3px" }}>
                                   Destinations
                              </p>
                              <ul className="mt-5" style={{ marginLeft: "5px" }}>
                                   {state.destination.map(({ categoryName }, i) => (
                                        <li key={i} className="capitalize">
                                             {categoryName}
                                        </li>
                                   ))}
                              </ul>
                         </div>
                         <div className="flex-1">
                              {/*<p className="text-base font-josefine">Adventures</p>*/}
                              {/*<ul className="mt-5">*/}
                              {/*     <li>Diving</li>*/}
                              {/*     <li>Rafting</li>*/}
                              {/*     <li>Skiing</li>*/}
                              {/*     <li>Trekking</li>*/}
                              {/*</ul>*/}
                         </div>
                         <div className="flex-1">
                              <p className="text-base font-josefine">Information</p>
                              <ul className="mt-5">

                                   <li>About US</li>
                                   <li>Online Query</li>
                                   <li>Become Partner</li>
                                   <li>Terms And Condition</li>
                              </ul>
                         </div>
                         <div className="flex-1">
                              {/*<p className="text-base font-josefine">Our Trip</p>*/}
                              {/*<ul className="mt-5">*/}
                              {/*     <li>Driving</li>*/}
                              {/*     <li>Rating</li>*/}
                              {/*     <li>Skilling</li>*/}
                              {/*</ul>*/}
                         </div>
                    </div>
               </div>
          </div>
     );
};
