import React, { FC, ReactNode } from "react";
import emailJs from "@emailjs/browser";

import { NavBar } from "../nav";
import { Footer } from "../footer";
import { handleEmail, handleName, toggleContactModal, useLayoutApp } from "../../redux/app";
import { useAppDispatch } from "../../redux/hooks";
import { toast } from "react-toastify";
import { ContactModal } from "../../component";

interface MainLayoutProps {
     children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
     const { contactModel, contactForm } = useLayoutApp();
     const dispatch = useAppDispatch();

     const SendEnquiry = async () => {
          if (!contactForm.date || !contactForm.name || !contactForm.place) {
               toast.error("fill up all the fields");
          } else {
               emailJs.init({
                    publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
                    limitRate: {
                         id: "the_valley",
                         throttle: 10000,
                    },
               });
               const response = await emailJs.send(
                    process.env.REACT_APP_EMAIL_JS_SERVICE_ID as string,
                    process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID as string,
                    {
                         name: contactForm.name,
                         place: contactForm.place,
                         date: contactForm.date,
                         email: contactForm.email,
                    }
               );
               if (response.status === 200) {
                    toast.success(`We have captured your response The Valley Team will be reach you soon`);
                    dispatch(toggleContactModal(false));
                    dispatch(handleEmail(""));
                    dispatch(handleName(""));
               } else {
                    toast.error("Failed to send email please try again!");
               }
          }
     };

     return (
          <div className="relative">
               <NavBar />
               <main className="z-10">{children}</main>
               {contactModel && <ContactModal SendEnquiry={SendEnquiry} />}
               <Footer />
          </div>
     );
};
