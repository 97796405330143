import { createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";

export interface PackagesProps {
  id: string;
  images: string[];
  details: string;
  inclusions: string[];
  exclusions: string[];
  price: string;
  label: string;
}

export interface DestinationProps {
  id: string;

  thumbnail: string;
  thumbnail1: string;
  categoryName: string;
  subCategory: string;
  packages: PackagesProps[];
}

interface DestinationSliceProps {
  destination: DestinationProps[];
  destination2: {
    id: number;
    image: string;
    title: string;
    subTitle?: string;
    descriptions?: string;
    inclusion: string[];
    exclusion: string[];
    price: number;
    gallery: any[];
  }[];
}

const initialState: DestinationSliceProps = {
  destination: [
    {
      id: "1",
      categoryName: "Singapore",
      subCategory: "Magical Singapore",
      thumbnail:
        "https://digiblu-projects.com/travellis/wp-content/uploads/2023/11/y092H3.jpg",
      thumbnail1: "",
      packages: [
        {
          id: "1",
          details:
            "This little country on the Malay Peninsula invites you to visit its landmarks. It’s one of the three sovereign city-states still in existence in the world and the only one that’s an island. But don’t let that fool you, as the country offers plenty of activities and a staggering spectacle of a play of lighting and architecture.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/siungapore-1-900x500.png",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-4k-full-hd-wallpaper-preview.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-city-urban-skyscrapers.jpg",
          ],
          inclusions: [

            "Daily breakfast at the hotel",
            "Return airport transfers on SIC",
            "Gardens by the Bay (Flower Dome + Supertree Observatory) on SIC Basis",
            "Sentosa Island Tour (Cable car Skpass + Wings of Time 07:40 pm) on SIC Basis",
            "Singapore Familiarization Drive on SIC Basis",
            "Universal Studios tour on SIC Basis",
            "Night Safari tour on SIC Basis",
            "E-tickets will be provided for the mentioned tours",
          ],
          price: "79,999",
          label: "4 Nights Singapore",
        },
        {
          id: "2",
          details:
            "This little country on the Malay Peninsula invites you to visit its landmarks. It’s one of the three sovereign city-states still in existence in the world and the only one that’s an island. But don’t let that fool you, as the country offers plenty of activities and a staggering spectacle of a play of lighting and architecture.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/siungapore-1-900x500.png",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-4k-full-hd-wallpaper-preview.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-city-urban-skyscrapers.jpg",
          ],
          inclusions: [

            "Daily breakfast at the hotel",
            "Return airport transfers on SIC",
            "Gardens by the Bay (Flower Dome + Supertree Observatory) on SIC Basis",
            "Sentosa Island Tour (Cable car Skpass + Wings of Time 07:40 pm) on SIC Basis",
            "Singapore Familiarization Drive on SIC Basis",
            "Universal Studios tour on SIC Basis",
            "Night Safari tour on SIC Basis",
            "E-tickets will be provided for the mentioned tours",
          ],
          price: "79,999",
          label: "4 Nights Singapore",
        },
        {
          id: "3",
          details:
            "This little country on the Malay Peninsula invites you to visit its landmarks. It’s one of the three sovereign city-states still in existence in the world and the only one that’s an island. But don’t let that fool you, as the country offers plenty of activities and a staggering spectacle of a play of lighting and architecture.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/siungapore-1-900x500.png",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-4k-full-hd-wallpaper-preview.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-city-urban-skyscrapers.jpg",
          ],
          inclusions: [

            "Daily breakfast at the hotel",
            "Return airport transfers on SIC",
            "Gardens by the Bay (Flower Dome + Supertree Observatory) on SIC Basis",
            "Sentosa Island Tour (Cable car Skpass + Wings of Time 07:40 pm) on SIC Basis",
            "Singapore Familiarization Drive on SIC Basis",
            "Universal Studios tour on SIC Basis",
            "Night Safari tour on SIC Basis",
            "E-tickets will be provided for the mentioned tours",
          ],
          price: "79,999",
          label: "4 Nights Singapore",
        },
      ],
    },
    {
      id: "2",
      categoryName: "Maldives",
      subCategory: "Island Time with Dhigali",
      thumbnail:
        "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/raphael-nogueira-CErddu-JwKw-unsplash-700x500.jpg",
      thumbnail1: "",
      packages: [
        {
          id: "1",
          details:
            "A string of Beautiful Islands, Maldives is Home to Stunning Beaches, Friendly Locals, Delicious Seafood, and Thrilling Water Sports. Boasting of beautiful marine life and clear waters, Maldives is the place to be if you’re a scuba diving and snorkeling enthusiast.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/raphael-nogueira-CErddu-JwKw-unsplash-700x500.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-4k-full-hd-wallpaper-preview.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-city-urban-skyscrapers.jpg",
          ],
          inclusions: [

            "Nights Beach Bungalow + 1 Night Lagoon Villa with Pool",
            "Premium All Inclusive",
            "Premium liquor – JW Black Lable served on a pouring basis at the Bar Outlets",
            "Return Airport Transfer by Seaplane on SIC",
            "One(1) complimentary glass bottom boat excursion during your stay",
            "One(1) complimentary dolphin cruise excursion during your stay",
            "Complimentary non-motorized water sports Kayak and Stand Up Padle Board (exclude Catamaran/Surfing)",
            "Complimentary snorkeling equipment during the stay",
          ],
          price: "79,999",
          label: "4 Nights Singapore",
        },
        {
          label: "3 Nights Maldives",
          details:
            "A string of Beautiful Islands, Maldives is Home to Stunning Beaches, Friendly Locals, Delicious Seafood, and Thrilling Water Sports. Boasting of beautiful marine life and clear waters, Maldives is the place to be if you’re a scuba diving and snorkeling enthusiast.",
          id: "2",
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/jcob-nasyr-hZPYwYR02Yo-unsplash-900x500.jpg",
          ],
          inclusions: [

            "Nights Beach Bungalow + 1 Night Lagoon Villa with Pool",
            "Premium All Inclusive",
            "Premium liquor – JW Black Lable served on a pouring basis at the Bar Outlets",
            "Return Airport Transfer by Seaplane on SIC",
            "One(1) complimentary glass bottom boat excursion during your stay",
            "One(1) complimentary dolphin cruise excursion during your stay",
            "Complimentary non-motorized water sports Kayak and Stand Up Padle Board (exclude Catamaran/Surfing)",
            "Complimentary snorkeling equipment during the stay",
          ],
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          price: "152,999",
        },
        {
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/mehmet-turgut-kirkgoz-0ASU0LSWp6g-unsplash-900x500.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/e-b-a071xQp8Xwo-unsplash.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/adib-harith-fadzilah-7iYX9dX0Gwg-unsplash.jpg",
          ],
          label: "Chasing Sunset with Kurumba",
          details:
            "A string of Beautiful Islands, Maldives is Home to Stunning Beaches, Friendly Locals, Delicious Seafood, and Thrilling Water Sports. Boasting of beautiful marine life and clear waters, Maldives is the place to be if you’re a scuba diving and snorkeling enthusiast.",
          inclusions: [

            "Dine Around All Inclusive",
            "Lunch with a choice of two menus in three locations with beverages. Dinner with a choice of seven restaurants with beverages. Additional Beverage Outlets: Athiri, Kandu Bar & Fez.",
            "Non-alcoholic: Soft drinks, canned juices, water, coffee & tea. Alcoholic: A selection of beers, wines, liquors & cocktails",
            "Return Airport Transfer by Speedboat on SIC",
            "30 Minutes Photo Session with 1 printed photo",
            "1 hr Spa Treatment for Adults once during the stay",
            "45 Minutes Kayak Per day",
            "Snorkeling Equipment",
            "Multi-Purpose Sports Court",
            "Tennis Courts and Tennis Balls",
          ],
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          id: "3",
          price: "97,999",
        },
        {
          label: "Chasing Sunset with Kurumba",
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/tobias-reich-1GgWbP74phY-unsplash-900x500.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/redcharlie-k2zWqv_yfNM-unsplash.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/leo-moko-FnmyoqhG2lE-unsplash.jpg",
          ],
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          details:
            "A string of Beautiful Islands, Maldives is Home to Stunning Beaches, Friendly Locals, Delicious Seafood, and Thrilling Water Sports. Boasting of beautiful marine life and clear waters, Maldives is the place to be if you’re a scuba diving and snorkeling enthusiast.",
          id: "4",
          inclusions: [

            "Dine Around All Inclusive",
            "Lunch with a choice of two menus in three locations with beverages. Dinner with a choice of seven restaurants with beverages. Additional Beverage Outlets: Athiri, Kandu Bar & Fez.",
            "Non-alcoholic: Soft drinks, canned juices, water, coffee & tea. Alcoholic: A selection of beers, wines, liquors & cocktails",
            "Return Airport Transfer by Speedboat on SIC",
            "30 Minutes Photo Session with 1 printed photo",
            "1 hr Spa Treatment for Adults once during the stay",
            "45 Minutes Kayak Per day",
            "Snorkeling Equipment",
            "Multi-Purpose Sports Court",
            "Tennis Courts and Tennis Balls",
          ],
          price: "108,999",
        },
        {
          label: "A Little Slice of Heaven on Earth with Velassaru",
          details:
            "A string of Beautiful Islands, Maldives is Home to Stunning Beaches, Friendly Locals, Delicious Seafood, and Thrilling Water Sports. Boasting of beautiful marine life and clear waters, Maldives is the place to be if you’re a scuba diving and snorkeling enthusiast.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          inclusions: [

            "Full Board Plus",
            "20% Discount on Spa Treatments Included",
            "1 Complimentary Floating Breakfast",
            "Return Airport Transfer by Speedboat on SIC",
            "Complimentary Morning Cruise",
            "Complimentary guided lagoon snorkeling tour on the house reef, twice a week",
            "Complimentary sunset beach yoga, three times a week",
            "Complimentary 30-minute Portrait Photography session once per stay",
            "Complimentary snorkeling equipment during the stay",
          ],
          id: "5",
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2022/07/luca-bravo-134776-unsplash-900x500.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/michelle-oude-maatman-hQ1UFmuAPB4-unsplash.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/thom-milkovic-iydgr7QN-Yk-unsplash.jpg",
          ],
          price: "117,999",
        },
        {
          label: "A Little Slice of Heaven on Earth with Velassaru",
          details:
            "A string of Beautiful Islands, Maldives is Home to Stunning Beaches, Friendly Locals, Delicious Seafood, and Thrilling Water Sports. Boasting of beautiful marine life and clear waters, Maldives is the place to be if you’re a scuba diving and snorkeling enthusiast.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          id: "6",
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/benjamin-suter-CgoRzWX4CDg-unsplash-900x500.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/simon-smith-B4qBdlNIuS0-unsplash.jpg",
          ],
          inclusions: [

            "Full Board Plus",
            "20% Discount on Spa Treatments Included",
            "1 Complimentary Floating Breakfast",
            "Return Airport Transfer by Speedboat on SIC",
            "Complimentary Morning Cruise",
            "Complimentary guided lagoon snorkeling tour on the house reef, twice a week",
            "Complimentary sunset beach yoga, three times a week",
            "Complimentary 30-minute Portrait Photography session once per stay",
            "Complimentary snorkeling equipment during the stay",
          ],
          price: "135,999",
        },
      ],
    },
    {
      id: "3",
      categoryName: "istanbul",
      subCategory: "7 Nights Turkey",
      thumbnail:
        "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/turkey-700x500.png",
      thumbnail1: "",
      packages: [
        {
          id: "1",
          details:
            "Turkey is home to some of the most alluring destinations. A mecca for art and history lovers, the stunning Turkish land is shrouded in an elegant archaic aura. The breathtaking architecture of Hagia Sophia will leave you awestruck, operating as a museum; it is one of the best-preserved ancient structures in the world. Epliesus is an ancient city that houses well-preserved Roman and Greek ruins along with a temple, theatre, and library. A tour of the age-old town will make you marvel at its timeless beauty.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/hagia-sophia-exterior-768x500.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/1487915512_turkey.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/GettyImages-1232792559-e1644357555255.webp",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/hagia-sophia-exterior.jpg",
          ],
          inclusions: [

            "Daily breakfast at the hotel",
            "Local Turkish lunch during sightseeing",
            "All airport transfers on a Private basis",
            "All full-day and half-day tours on a private basis",
          ],
          price: "99,999",
          label: "4 Nights Singapore",
        },
      ],
    },
    {
      categoryName: "India",
      id: "4",
      subCategory: "Great India",
      packages: [
        {
          label: "Andaman Fun filled",
          details:
            "The Andaman Islands have a unique combination of rich beaches, rising hinterland and dense equatorial forests.{`\n`}This memorable trip cover almost all elements and specially Barathang sector whereas the journey starts by early morning and end by evening covering lush green drive from Port Balir to Barathang, experiencing localway of river crossing by ferry and travel through mangroves jungle by a small boat to reach lime stone caves. You also would have experience to see Mud Volcano in the region.{`\n`}Note: Rates are commissionable to our B2B partners, Contact us for more details.",
          id: "1",
          inclusions: [
            "Well-appointed accommodation",
            "Meal Plan – CP (Bed & Breakfast)",
            "Sightseeing as per itinerary",
            "All Monuments, Museums, Cultural Events and activities fees as per itinerary",
            "Return airport/ jetty Transfers in AC vehicle as per itinerary",
            "Cruise transfers Port Blair –Neil Island – Havelock – Port Blair by private ferry Subject to availability",
          ],
          exclusions: [
            "05.00% Goods & services tax extra (GST)",
            "Any kind of personal expenses or optional tours/extra meals ordered",
            "Anything not specifically mentioned under the head “Prices included”",
            "Tips, insurance, laundry, phone calls",
            "The services of vehicles is not on disposal basis & finishing the sightseeing tour as per the itinerary",
            "Any kind of drinks (alcohol, Mineral, aerated, bed tea on tour or any other snack while waiting at airport or waiting for jetty)",
            "Camera fee, soft or hard drinks & starters",
            "Medical & travel insurance.",
            "Additional costs due to flight cancellation etc. Cost incidental to any change in the itinerary / stay on account of flight cancellation due to bad weather, ill health, and / or any factors beyond control.",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/Radhanagar-Beach.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/Best-Places-to-Visit-in-Andaman.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/Andamans-Tour-Package.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/3060a9d4-172f-11ea-9ee4-11f260415385.jpegg",
          ],
          price: "79,999",
        },
        {
          label: "Andamans- Iconic Nature",
          details:
            "The Andaman Islands have a unique combination of rich beaches, rising hinterland and dense equatorial forests.{\n}This memorable trip cover almost all elements and specially Barathang sector whereas the journey starts by early morning and end by evening covering lush green drive from Port Balir to Barathang, experiencing localway of river crossing by ferry and travel through mangroves jungle by a small boat to reach lime stone caves. You also would have experience to see Mud Volcano in the region.{\n}Note: Rates are commissionable to our B2B partners, Contact us for more details.",
          id: "2",
          inclusions: [
            "Accommodation in Standard, deluxe Room on Double/twin sharing on Select Package.",

            "Transfer and Sightseeing as per the below tour Itinerary by Non a/C",
            "Welcome drink on arrival.",
            "Meals MAPAI (Breakfast And Dinner)",
            "Transport by Non AC Private Innova, Scorpio, Tavera, Xylo, Etius/Indigo/Swift & Tata Winger/Tempo Traveler.",
            "Toyota Innova & Tempo Traveler for Deluxe & Luxury Package Options",
            "(02 – 3 Small Cabs, 04 – 07 Pax in SUV Cabs & above 08 Pax onward Vehicle will be Tata Winger/Tempo).",
            "All toll taxes, driver’s allowances, Fuel charges, interstate permit if necessary and all taxes.Shikara Ride 1-2 Hours",
            "Vehicle Can be used for any medical Emergency During Trip",
          ],
          exclusions: [
            "Any sightseeing or excursion that is not mentioned in the itinerary.",
            "Monument Fee, Guide, other expenses not mentioned in inclusion.",
            "Any item of personal requirement, such as drinks, laundry, telephone, etc.",
            "Any medical or evacuation expenses.",
            "Any expenses occur due to natural climate, security and other unexpected reason.",
            "Any claim due to road blocks, curfew, accident etc.",
            "Any type of insurance – personal, medical, travel etc.",
            "Any other services’ not specified in the Colum “Inclusions”.",
            "All kind of personal expenses such as tips, laundry, telephone bills and beverages, Camera Fees.",
            "Cable Car Tickets, Air Fare, Any meals unless and otherwise specifically mentioned.",
            "No refund on any unused transportation or any services in any circumstances.",
            "If the GST Bill Required Then 5% GST will be charged on total billing",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/landscape-view-himalayas-kashmir.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/jammu-and-kashmir-wallpaper.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/dczBkH.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/wjGABd.jpg",
          ],
          price: "79,999",
        },
        {
          label: "Kashmir CALLING Tour",
          details:
            "Srinagar – Gulmarg O/N – Gondola – Pahalgam O/N – Pony Ride – Mughal Gardens – Shikara Ride – Srinagar",
          id: "3",
          inclusions: [
            "Accommodation in Standard, deluxe Room on Double/twin sharing on Select Package.",

            "Transfer and Sightseeing as per the below tour Itinerary by Non a/C",
            "Welcome drink on arrival.",
            "Meals MAPAI (Breakfast And Dinner)",
            "Transport by Non AC Private Innova, Scorpio, Tavera, Xylo, Etius/Indigo/Swift & Tata Winger/Tempo Traveler.",
            "Toyota Innova & Tempo Traveler for Deluxe & Luxury Package Options",
            "(02 – 3 Small Cabs, 04 – 07 Pax in SUV Cabs & above 08 Pax onward Vehicle will be Tata Winger/Tempo).",
            "All toll taxes, driver’s allowances, Fuel charges, interstate permit if necessary and all taxes.Shikara Ride 1-2 Hours",
            "Vehicle Can be used for any medical Emergency During Trip",
          ],
          exclusions: [
            "Any sightseeing or excursion that is not mentioned in the itinerary.",
            "Monument Fee, Guide, other expenses not mentioned in inclusion.",
            "Any item of personal requirement, such as drinks, laundry, telephone, etc.",
            "Any medical or evacuation expenses.",
            "Any expenses occur due to natural climate, security and other unexpected reason.",
            "Any claim due to road blocks, curfew, accident etc.",
            "Any type of insurance – personal, medical, travel etc.",
            "Any other services’ not specified in the Colum “Inclusions”.",
            "All kind of personal expenses such as tips, laundry, telephone bills and beverages, Camera Fees.",
            "Cable Car Tickets, Air Fare, Any meals unless and otherwise specifically mentioned.",
            "No refund on any unused transportation or any services in any circumstances.",
            "If the GST Bill Required Then 5% GST will be charged on total billing",
          ],
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/Lqkei8-900x500.webp",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/dczBkH.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/b9uaGU.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/wjGABd.jpg",
          ],
          price: "32,000",
        },
      ],
      thumbnail1: "",
      thumbnail:
        "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/Radhanagar-Beach-900x500.jpg",
    },
    {
      categoryName: "dubai",
      id: "5",
      subCategory: "4 Nights Dubai",
      packages: [
        {
          details:
            "Explore the alleyways of the Gold and Textile Souk or the Heritage Village to learn about Dubai’s arts, customs, and architecture. See the historic Grand Mosque with the city’s tallest minaret, located on the Bur Dubai side of the Creek near the Ruler’s Court.",
          exclusions: [],
          id: "1",
          images: [
            require("../../assets/dubai/dubai1.jpeg"),
            require("../../assets/dubai/BurjKhalifa.jpg"),
            require("../../assets/dubai/Desert safari.jpg"),
            require("../../assets/dubai/Desert safari1.jpg"),
            require("../../assets/dubai/Desert safari2.jpg"),
            require("../../assets/dubai/Dubai's Museum .jpg"),
          ],
          inclusions: [
            "Day 1: Arrival into Dubai. Lunch at the hotel. Evening Yacht Cruise in Dubai. Dinner at JBR",
            "Day 2: Post breakfast - Entry to Museum of Future + Lunch at Local Indian Restaurant + afternoon Desert Safari with BBQ Dinner (based on 06 pax per 4WD and standard campsite on sharing basis 1st Half Day Vehicle 01 x 35-Seater Vehicle & No Guide.)",
            "Day 3: Half Day Dubai City Tour with one-way mono rail ride + Lunch at Local Indian Restaurant. Visit to Dubai Mall - Entry to Burj Khalifa (124th Floor/Non-Prime Hours) + View of Dubai Mall fountain show + Dinner at Local Indian Restaurant -Full Day 01 x 35-Seater Vehicle &Guide",
            "Day 4: Entry to Miracle Garden + Lunch at your own + Entry to Global Village + Dinner at Local Indian Restaurant – Full Day 01 x 35-Seater & Guide",
            "Day 5: Gold Souq and Shopping + Lunch at Local Indian Restaurant – Full Day Vehicle 01x 35-Seater Vehicle & 1st Half Day Guide.",
            "Day 6: Morning leisure. Post lunch drive to Abu Dhabi. Visit Grand Mosque at evening. Then drive through Corniche and then check-in to the hotel in Abu Dhabi.",
            "Day 7: Breakfast at Abu Dhabi hotel. Post breakfast, visit the BAPS at morning, Palace, then lunch at Ferrari world. Post lunch do photo shots at Ferrari and then visit the dates market and back to the hotel in Abu Dhabi. Gala Dinner at Local Indian Restaurant (02 hours free flow of standard alcohol & 02 Veg +02 Non-Veg Starters)– DJ to be included for Gala dinner and a cake to be organized.\n" +
              "(For shopping- Lulu Mall/Yas Mall/Marina Mall. For Arabian coffee- Central market souq)",
            "Day 8: At Leisure + 12:00 hrs. check-out + Drop off to Abu Dhabi Airport with English Speaking Driver – Departure Transfers 01 x 35-Seater. If anyone from Dubai, you will have to please give Dubai transfers.",
          ],
          label: "4 Nights Dubai",
          price: "47,000",
        },
      ],
      thumbnail: require("../../assets/dubai/dubai2.png"),
      thumbnail1: require("../../assets/dubai/dubaiwide.png"),
    },
    {
      categoryName: "Bali",
      subCategory: "Bali Beach Bliss",
      id: "6",
      packages: [
        {
          details:
            "Known as the Island of the Gods, Bali is an island paradise that has much to offer its visitors. Lush rice fields, ancient temples, magical sunsets, traditional villages, idyllic beaches, and the best hotels in the world are some of the island’s most remarkable attractions.",
          exclusions: [
            "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
            "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
            "Any other items not mentioned in the “tour price includes” are also not part of the package.",
          ],
          id: "1",
          images: [
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/1-1.jpeg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/2d.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/8994658f-13ad-4106-bde4-856450359f47.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/bajra-sandhi-monument-bali-denpasar-shutterstock_757549327.jpg_835af458e0.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/Beratan-lake-bratan-bedugul.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/het-uitzicht-vanaf-de.jpg",
            "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/Pasang-Wifi-Gratis-di-Pasar-Badung-Dorong-Masyarakat-Belanja-ke-Pasar_804298.jpg",
          ],
          inclusions: [

            "Daily breakfast at the hotel",
            "Return airport transfers in Bali",
            "Full-day Bedugul with Tanah Lot Temple Tour",
            "Half-day Denpasar City tour including Bajra Shandi, 3D Museum, Puputan Square",
            "Full Day white water rafting at Ayung River ( Combinable with ATV ride )",
            "All tours and transfers on Pvt basis",
          ],
          label: "4 Nights Bali",
          price: "33,999",
        },
      ],
      thumbnail:
        "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/bali-700x500.png",
      thumbnail1: "",
    },
  ],
  destination2: [
    {
      id: 1,
      image: require("../../assets/dubai/Dubai's Museum of the Future.jpg"),
      title: "dubai",
      subTitle: "Alluring Dubai",
      descriptions:
        "Explore the alleyways of the Gold and Textile Souk or the Heritage Village to learn about Dubai’s arts, customs, and architecture. See the historic Grand Mosque with the city’s tallest minaret, located on the Bur Dubai side of the Creek near the Ruler’s Court.",
      inclusion: [

        "Meals – Breakfast",
        "Return Airport transfer on PVT. Basis",
        "Half-Day Dubai City Tour with Dubai Frame Photo stop with Licensed Guide.",
        "Marina Dhow Cruise with Dinner.",
        "Global Village Admission Ticket.",
        "Burj Khalifa on 124thFloor (Non-Prime time).",
        "Desert Safari with BBQ Dinner and Live Entertainment.",
      ],
      exclusion: [
        "Tips – exclude payable to the Tour manager on Tour.",
        "GST and TCS are applicable additionally.",
        "Excluded from the group tour package are the following expenses:",
        "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
        "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
        "Any other items not mentioned in the “tour price includes” are also not part of the package.",
      ],
      price: 47000,
      gallery: [
        require("../../assets/dubai/dubai1.jpeg"),
        require("../../assets/dubai/dubai22.jpeg"),
        require("../../assets/dubai/dubai23.jpeg"),
        require("../../assets/dubai/dubai24.jpeg"),
        require("../../assets/dubai/dubai25.jpeg"),
        require("../../assets/dubai/dubai26.jpeg"),
      ],
    },
    {
      id: 2,
      image:
        "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-2.jpg",
      title: "singapore",
      subTitle: "Magical Singapore",

      descriptions:
        "This little country on the Malay Peninsula invites you to visit its landmarks. It’s one of the three sovereign city-states still in existence in the world and the only one that’s an island. But don’t let that fool you, as the country offers plenty of activities and a staggering spectacle of a play of lighting and architecture.",
      inclusion: [

        "Daily breakfast at the hotel",
        "Return airport transfers on SIC",
        "Gardens by the Bay (Flower Dome + Supertree Observatory) on SIC Basis",
        "Sentosa Island Tour (Cable car Skpass + Wings of Time 07:40 pm) on SIC Basis",
        "Singapore Familiarization Drive on SIC Basis",
        "Universal Studios tour on SIC Basis",
        "Night Safari tour on SIC Basis",
        "E-tickets will be provided for the mentioned tours",
      ],
      exclusion: [
        "Tips – exclude payable to the Tour manager on Tour.",
        "Flight Tickets and Visa",
        "GST and TCS are applicable additionally.",
        "Excluded from the group tour package are the following expenses:",
        "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
        "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
        "Any other items not mentioned in the “tour price includes” are also not part of the package.",
      ],
      price: 79999,
      gallery: [
        "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/siungapore-1-900x500.png",
        "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-4k-full-hd-wallpaper-preview.jpg",
        "https://digiblu-projects.com/travellis/wp-content/uploads/2017/01/singapore-city-urban-skyscrapers.jpg",
      ],
    },
    {
      id: 3,
      image:
        "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-1.jpg",
      title: "bali",
      subTitle: "Bali Beach Bliss",
      descriptions:
        "Known as the Island of the Gods, Bali is an island paradise that has much to offer its visitors. Lush rice fields, ancient temples, magical sunsets, traditional villages, idyllic beaches, and the best hotels in the world are some of the island’s most remarkable attractions.",
      inclusion: [

        "Daily breakfast at the hotel",
        "Return airport transfers in Bali",
        "Full-day Bedugul with Tanah Lot Temple Tour",
        "Half-day Denpasar City tour including Bajra Shandi, 3D Museum, Puputan Square",
        "Full Day white water rafting at Ayung River ( Combinable with ATV ride )",
        "All tours and transfers on Pvt basis",
      ],
      exclusion: [
        "Flight Tickets and Visa",
        "GST and TCS are applicable additionally.",
        "Tips – exclude payable to the Tour manager on Tour.",
        "Excluded from the group tour package are the following expenses:",
        "Any other items not mentioned in the “tour price includes” are also not part of the package.",
        "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
        "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
      ],
      price: 33999,
      gallery: [
        require("../../assets/bali/1.jpg"),
        require("../../assets/bali/2.jpg"),
        require("../../assets/bali/3.jpg"),
        require("../../assets/bali/4.jpg"),
        require("../../assets/bali/5.jpg"),
        require("../../assets/bali/6.jpg"),
      ],
    },
    {
      id: 4,
      image:
        "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-4.jpg",
      title: "thailand",
      descriptions:
        "Known as the Island of the Gods, Bali is an island paradise that has much to offer its visitors. Lush rice fields, ancient temples, magical sunsets, traditional villages, idyllic beaches, and the best hotels in the world are some of the island’s most remarkable attractions.",
      inclusion: [

        "Daily breakfast at the hotel",
        "Return airport transfers in Bali",
        "Full-day Bedugul with Tanah Lot Temple Tour",
        "Half-day Denpasar City tour including Bajra Shandi, 3D Museum, Puputan Square",
        "Full Day white water rafting at Ayung River ( Combinable with ATV ride )",
        "All tours and transfers on Pvt basis",
      ],
      exclusion: [
        "Flight Tickets and Visa",
        "GST and TCS are applicable additionally.",
        "Tips – exclude payable to the Tour manager on Tour.",
        "Excluded from the group tour package are the following expenses:",
        "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
        "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
        "Any other items not mentioned in the “tour price includes” are also not part of the package.",
      ],

      price: 79999,
      gallery: [
        require("../../assets/bali/1.jpg"),
        require("../../assets/bali/2.jpg"),
        require("../../assets/bali/3.jpg"),
        require("../../assets/bali/4.jpg"),
        require("../../assets/bali/5.jpg"),
        require("../../assets/bali/6.jpg"),
      ],
    },
    {
      id: 5,
      image:
        "https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/region-3.jpg",
      title: "turkey",
      descriptions:
        "Known as the Island of the Gods, Bali is an island paradise that has much to offer its visitors. Lush rice fields, ancient temples, magical sunsets, traditional villages, idyllic beaches, and the best hotels in the world are some of the island’s most remarkable attractions.",
      inclusion: [

        "Daily breakfast at the hotel",
        "Return airport transfers in Bali",
        "Full-day Bedugul with Tanah Lot Temple Tour",
        "Half-day Denpasar City tour including Bajra Shandi, 3D Museum, Puputan Square",
        "Full Day white water rafting at Ayung River ( Combinable with ATV ride )",
        "All tours and transfers on Pvt basis",
      ],
      exclusion: [
        "Tips – exclude payable to the Tour manager on Tour.",
        "Flight Tickets and Visa",
        "GST and TCS are applicable additionally.",
        "Excluded from the group tour package are the following expenses:",
        "Porterage, Laundry, Telephone charges, Shopping, Wines & Alcoholic Beverages, Personal items, and any Food or Drink not listed on the Menu.",
        "Additionally, any additional costs resulting from Individual Illness, Accidents, Hospitalization, or Personal Emergencies are not covered.",
        "Any other items not mentioned in the “tour price includes” are also not part of the package.",
      ],
      price: 79999,
      gallery: [
        require("../../assets/singapore/1.jpg"),
        require("../../assets/singapore/2.jpg"),
        require("../../assets/singapore/3.jpg"),
        require("../../assets/singapore/4.jpg"),
        require("../../assets/singapore/5.jpg"),
        require("../../assets/singapore/6.jpg"),
      ],
    },
  ],
};

const DestinationSlice = createSlice({
  initialState,
  name: "destination",
  reducers: {},
});

export const useDestinationSlice = () =>
  useAppSelector((state) => {
    return state.destination;
  });
export const DestinationReducer = DestinationSlice.reducer;
