import React from "react";
import { Container } from "../container";
import { MdOutlinePhoneInTalk } from "react-icons/md";

export const ReachOut = () => {
     return (
          <div
               className="bg-primary-500 py-10 text-white"
               style={{
                    backgroundImage:
                         "url(https://demo.themexpert.com/wordpress/valley/travel-agency/wp-content/uploads/sites/2/2019/07/map-1.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
               }}
          >
               <Container>
                    <p className="text-base">- Reach Out Now</p>
                    <div className="xl:flex lg:flex 2xl:flex flex-wrap gap-10">
                         <div className="flex-1">
                              <h6 className="text-[45px] font-josefine">
                                   We’re available for 24 / 7 hours a day! Reach Out Now to require a detailed analysis and
                                   assessment of your plan.
                              </h6>
                         </div>
                         <div className="xl:w-[40%] flex justify-center items-center flex-col">
                              <MdOutlinePhoneInTalk size={83} />
                              <p className="text-[30px]">+91 -8591556868</p>

                              <div>
                                   <button className="bg-white text-primary-500 px-14 mt-3 py-4 rounded-md capitalize border-2 border-transparent hover:bg-transparent hover:text-white hover:border-white transition-all duration-300">
                                        contact us
                                   </button>
                              </div>
                         </div>
                    </div>
               </Container>
          </div>
     );
};
