import React from "react";
import { MainLayout } from "../../layout";
import { ContactForm } from "../../component";

export const WhyUsPage = () => {
  const venuePoints = [
    {
      label: "Venue Selection",
      point:"From a hot-air ballooning over deserts at dawn to sipping on champagne on the top of forts, our choice of venues go beyond the brief to cater to specific needs.",
    },
    {
      label: "Logistical Management",
      point: "Our guests are taken care from hotels, & operations, our staff & partners across destinations are always ready to go the extra mile to meet your needs.",
    },
    {
      label: "Hotel Selection",
      point: "From a value hotel chains to exclusive hotels and resorts for your particular needs we make sure each place weaves story for you to take home.",
    },
    {
      label: "Food & Beverage",
      point: "From different menus for the different travellers or groups, we make sure your meals delight your taste buds without disappointing your stomach.",
    },
    {
      label: "VIP Management",
      point:
          "From a seamless meet and greet at the airport, to providing luxury transportation throughout the operations, arranging private jets to following protocols. ",
    },
    {
      label: "Group Activities \n",
      point:
          "From a coordination across the different geographies to planning, organizing visits Travelicious always try to make sure every individual returns as a team. ",
    },
    {
      label: "Product Launches \n",
      point:
          "With experience across several industries & brands, we provide perfect ideas for you to play a master stroke. From diversity in terrains to diversity in cultures. \n ",
    },
  ];



  return (
      <MainLayout>
        <div
            style={{
              backgroundImage:
                  "url(https://images.pexels.com/photos/3573383/pexels-photo-3573383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
        >
          <div className="h-[60vh] bg-gray-900 w-full bg-opacity-50 flex justify-center items-center">
            <h6 className="text-4xl font-semibold text-white capitalize">
              Our Services
            </h6>
          </div>
        </div>
        <div className="grid xl:w-[80%] xl:px-0 px-5 mx-auto my-10 py-10 gap-10 grid-cols-12">
          <div className="xl:col-span-8 col-span-12">
            <h3 className="text-3xl font-semibold text-center capitalize border-b-4 pb-3 rounded-md w-[30%] mx-auto border-primary-500">
              What we  <span className="text-primary-500">provide</span>
            </h3>
            <div className="grid  gap-10 grid-cols-1 items-center justify-center my-10 2 md:grid-cols-1 sm:grid-cols-1">
              {venuePoints.map(({ label, point }, i) => (
                  <div className="hover:bg-primary-100 p-3 rounded-md">
                    <span className="text-primary-500 text-2xl font-semibold font-josefine">{label}</span>
                    <h6 className="text-md text-gray-500">{point}</h6>
                  </div>
              ))}
            </div>
          </div>
          <div className="xl:col-span-4 col-span-4">
            <div className="mt-32">
              <ContactForm formLabel="Booking for corporate" />
            </div>
          </div>
        </div>
      </MainLayout>
  );
};

