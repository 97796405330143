import React, { FC } from "react";
import { CarouselItem3 } from "../carousel-item-3";
import { usePackageSlice } from "../../redux/app";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Container } from "../container";
import { Link } from "react-router-dom";

export interface Packages2Props {
  label: string;
}

export const Packages2: FC<Packages2Props> = ({ label }) => {
  const { expPackage } = usePackageSlice();

  return (
    <div className="bg-sky-400 mt-10 relative">
      <Container>
        <div className="flex justify-between  mb-10 items-center py-5 z-50">
          <h6 className="text-[40px] font-[400px] text-white capitalize font-josefine leading-[45px]">
            {label}
          </h6>
          <div className="flex gap-3 items-center">
            <button className="border border-gray-100 p-2 rounded-md">
              <AiOutlineLeft size={22} className="text-white" />
            </button>
            <button className="border border-gray-100 p-2 rounded-md">
              <AiOutlineRight size={24} className="text-white" />
            </button>
          </div>
        </div>
        <div className="grid gap-10 xl:grid-cols-4 overflow-x-auto w-full z-50 pb-10">
          {/*{expPackage.map(({ placeName, thumbnail, id, content }, i) => (*/}
          {/*  <Link to={`/exp-packages/${id}`}>*/}
          {/*    <CarouselItem3*/}
          {/*      image={thumbnail[0]}*/}
          {/*      key={i}*/}
          {/*      label={label}*/}
          {/*      packageName={placeName}*/}
          {/*    />*/}
          {/*  </Link>*/}
          {/*))}*/}
          {expPackage.map(({ placeName, thumbnail, id, content }, i) => (
              id !== 1 ? (
                  <Link to={`/exp-packages/${id}`} key={i}>
                    <CarouselItem3
                        image={thumbnail[0]}
                        label={label}
                        packageName={placeName}
                    />
                  </Link>
              ) : (
                  <div key={i}>
                    <CarouselItem3
                        image={thumbnail[0]}
                        label={label}
                        packageName={placeName}
                    />
                  </div>
              )
          ))}
        </div>
      </Container>
    </div>
  );
};
