import Aos from "aos";
import React, { FC, useEffect } from "react";

interface CarouselItemProps {
     image: string;
     title?: string;
}

export const CarouselItem: FC<CarouselItemProps> = ({ image, title }) => {
     useEffect(() => {
          Aos.refresh();
     }, []);
     return (
          <div
               className="item xl:h-screen lg:h-screen 2xl:h-screen h-[30rem] w-full xl:w-screen object-cover relative"
               style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
               }}
          >
               <div
                    data-aos="fade-up"
                    className="absolute bottom-[20%] flex flex-col gap-5 justify-start items-start xl:w-[50%] w-full  xl:px-0 px-5 xl:left-[15%]"
               >
                    <h6 className="text-white text-[37px] font-josefine capitalize">{title}</h6>
                    <button type="button" className="bg-white bg-opacity-70 p-4 rounded-md">
                         Let's go now
                    </button>
               </div>
          </div>
     );
};
