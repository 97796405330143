import React from "react";
import { Link, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import clsx from "clsx";
import { AiOutlineLeft } from "react-icons/ai";
import { useDestinationSlice } from "../../redux/app";
import { MainLayout } from "../../layout";
import { ContactForm } from "../../component";

export const Destination2DetailsPage = () => {
  const { id } = useParams();
  const { destination2 } = useDestinationSlice();

  const mainProps = destination2.find(
    (props) => props.id === parseInt(id as string)
  );

  return (
    <MainLayout>
      <div className="py-32">
        <div className="container mx-auto xl:px-0 px-5 xl:w-[60%]">
          <div className="flex gap-10 items-center mb-10">
            <Link to="/">
              <AiOutlineLeft className="h-[30px] w-[30px]" />
            </Link>
            <h6 className=" xl:text-4xl text-xl font-semibold capitalize">
              <span>{mainProps?.subTitle}</span> - {mainProps?.title}
            </h6>
          </div>
          <Carousel autoPlay infiniteLoop renderIndicator={() => null}>
            {mainProps?.gallery.map((prop, id) => (
              <div style={{"height":"30vw"}}>
                <img
                  className="rounded-md shadow"
                  src={prop}
                  alt={id.toString()}
                />
              </div>
            ))}
          </Carousel>
          <div className="flex flex-col gap-20 mt-10">
            <p className="text-2xl font-semibold">
              Starts from - ₹ {mainProps?.price}
            </p>
            <div>
              <h6 className="text-2xl capitalize font-semibold">description</h6>
              <p className="text-gray-500">{mainProps?.descriptions}</p>
            </div>
            <hr />
            <div className="flex xl:flex-row lg:flex-row 2xl:flex-row flex-col xl:flex-wrap lg:flex-wrap 2xl:flex-wrap gap-10 flex-wrap">
              <div className="flex-1">
                <div>
                  <p className="uppercase font-semibold text-2xl">INCLUSIONS</p>
                  <ul
                    className="mt-3 list-disc"
                    style={{ paddingLeft: "1rem" }}
                  >
                    {mainProps?.inclusion.map((prop, i) => (
                      <li
                        key={i}
                        className="text-gray-500 text-base ont-medium"
                      >
                        {prop}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10">
                  <p className="uppercase font-semibold text-2xl">exclusion</p>
                  <ul
                    className="mt-3 list-disc"
                    style={{ paddingLeft: "1rem" }}
                  >
                    {mainProps?.exclusion.map((prop, i) => (
                      <li
                        key={i}
                        className={clsx(
                          "text-gray-500 text-base ont-medium",
                          i === 2 && "font-semibold list-none text-gray-900"
                        )}
                      >
                        {prop}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex-1">
                <ContactForm formLabel="Contact Us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
