import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  CorporateBookingPage,
  Destination2DetailsPage,
  DestinationDetailsPage,
  DestinationListPage,
  Homepage,
  WhyUsPage,
  ExpPackagePage,
} from "../pages";
import PlaceCard from "../pages/place/PlaceCard";
import Place from "../pages/place/place";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />

        <Route path="/why-us" element={<WhyUsPage />} />
        <Route path="/corp-booking" element={<CorporateBookingPage />} />
        <Route path="/exp-packages/:id" element={<ExpPackagePage />} />
        <Route
          path="/PopularDestination"
          element={<Place handleOrderPopup={() => {}} />}
        />
        <Route path="/places/:placeName" element={<DestinationListPage />} />
        <Route
          path="/places/:categoryName/:destinationName"
          element={<DestinationDetailsPage />}
        />
        <Route path="/:id" element={<Destination2DetailsPage />} />
        <Route
          path="/place"
          element={
            <PlaceCard
              img="4.jpg"
              title="Example Title"
              location="Example Location"
              description="Example Description"
              price={99.99}
              type="Example Type"
              handleOrderPopup={() => {}}
              popUp={false}
              hidePlaceCardBody={false}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
