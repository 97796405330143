import React from "react";
import { Link, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import clsx from "clsx";
import { AiOutlineLeft } from "react-icons/ai";
import {
  handleDate,
  handleEmail,
  handleName,
  handlePlace,
  useDestinationSlice,
  useLayoutApp,
} from "../../redux/app";
import { MainLayout } from "../../layout";
import { useAppDispatch } from "../../redux/hooks";
import { toast } from "react-toastify";
import emailJs from "@emailjs/browser";

export const DestinationDetailsPage = () => {
  const { categoryName, destinationName } = useParams();
  const state = useDestinationSlice();
  const { contactForm } = useLayoutApp();
  const dispatch = useAppDispatch();

  const SendEnquiry = async () => {
    if (!contactForm.date || !contactForm.name || !contactForm.place) {
      toast.error("fill up all the fields");
    } else {
      emailJs.init({
        publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
        limitRate: {
          id: "the_valley",
          throttle: 10000,
        },
      });
      const response = await emailJs.send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID as string,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID as string,
        {
          name: contactForm.name,
          place: contactForm.place,
          date: contactForm.date,
          email: contactForm.email,
        }
      );
      if (response.status === 200) {
        toast.success(
          `We have captured your response The Valley Team will be reach you soon`
        );
        dispatch(handleEmail(""));
        dispatch(handleName(""));
      } else {
        toast.error("Failed to send email please try again!");
      }
    }
  };

  // Find in an array
  const mainProps = state.destination
    .find((props) => props.categoryName === categoryName)
    ?.packages.find((prop) => prop.label === destinationName);

  return (
    <MainLayout>
      <div className="py-32">
        <div className=" mx-auto xl:px-0 px-5 xl:w-[60%] 2xl:w-[60%] lg:w-[60%]">
          <div className="flex gap-10 items-center mb-10">
            <Link to={`/places/${categoryName}`}>
              <AiOutlineLeft className="h-[30px] w-[30px]" />
            </Link>
            <h6 className=" xl:text-4xl text-xl font-semibold capitalize">
              {categoryName} - <span>{destinationName}</span>
            </h6>
          </div>
          <Carousel
            infiniteLoop
            showArrows
            showThumbs
            renderIndicator={() => null}
          >
            {mainProps?.images?.map((image, id) => (
              <div
                className="xl:h-[50vh]"
                key={id}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <img
                  className="w-full h-full object-cover"
                  src={image as unknown as string}
                  alt={id as any}
                />
              </div>
            ))}
          </Carousel>
          <div className="flex flex-col gap-1 mt-5">
            <p className="text-2xl font-semibold">
              Starts from - ₹ {mainProps?.price}
            </p>
            <div>
              <h6 className="text-2xl capitalize font-semibold">description</h6>
              <p className="text-gray-500">{mainProps?.details}</p>
            </div>
            <hr />

            <div className="flex xl:flex-row lg:flex-row 2xl:flex-row flex-col xl:flex-wrap lg:flex-wrap 2xl:flex-wrap gap-1 flex-wrap">
              <div className="flex-1">
                <div>
                  <p className="uppercase font-semibold text-2xl">INCLUSIONS</p>
                  <ul
                    className="mt-3 list-disc"
                    style={{ paddingLeft: "1rem" }}
                  >
                    {mainProps?.inclusions.map((prop, i) => (
                      <li
                        key={i}
                        className="text-gray-500 text-base ont-medium"
                      >
                        {prop}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-20">
                  <p className="uppercase font-semibold text-2xl">exclusion</p>
                  <ul
                    className="mt-3 list-disc"
                    style={{ paddingLeft: "1rem" }}
                  >
                    {mainProps?.exclusions.map((prop, i) => (
                      <li
                        key={i}
                        className={clsx(
                          "text-gray-500 text-base ont-medium",
                          i === 2 && "font-semibold list-none text-gray-900"
                        )}
                      >
                        {prop}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex-1">
                <div className="relative p-3 flex-auto bg-white shadow-lg rounded-lg border">
                  <h6 className="text-2xl">Contact Now</h6>
                  <div>
                    <label htmlFor="name" className="text-sm text-gray-500">
                      Your Name
                    </label>
                    <input
                      value={contactForm.name}
                      onChange={(event) =>
                        dispatch(handleName(event.target.value))
                      }
                      type="text"
                      id="name"
                      name="name"
                      className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
                      placeholder="Your name"
                    />
                    <label htmlFor="name" className="text-sm text-gray-500">
                      Your Email Address
                    </label>
                    <input
                      value={contactForm.email}
                      onChange={(event) =>
                        dispatch(handleEmail(event.target.value))
                      }
                      type="email"
                      id="email"
                      name="email"
                      className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
                      placeholder="abc@mail.com"
                    />
                    <label htmlFor="place" className="text-sm text-gray-500">
                      Select Place
                    </label>
                    <select
                      value={
                        (contactForm?.place?.length === 0 && categoryName) || ""
                      }
                      onChange={(event) =>
                        dispatch(handlePlace(event.target.value))
                      }
                      name="place"
                      id="place"
                      className="border-2 w-full focus:outline-none focus:border-primary-500 p-2  transition-all text-sm"
                    >
                      {state.destination.map(({ categoryName }) => (
                        <option
                          key={categoryName}
                          className="capitalize"
                          value={categoryName}
                        >
                          {categoryName}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="date" className="text-sm text-gray-500">
                      Select date of tour
                    </label>
                    <input
                      value={contactForm.date}
                      onChange={(e) => dispatch(handleDate(e.target.value))}
                      type="date"
                      id="date"
                      name="date"
                      className="border-2 w-full focus:outline-none focus:border-primary-500 uppercase p-2  transition-all text-sm"
                    />
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      className="bg-primary-500 text-white active:bg-primary-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                      type="button"
                      onClick={SendEnquiry}
                    >
                      Send Enquiry
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
