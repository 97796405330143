import React, { FC } from "react";
// import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { CarouselItem2 } from "../carousel-item-2";
import { useDestinationSlice } from "../../redux/app";
import ReactOwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export interface PopularDestinationProps {
     componentHeader: string;
}

export const PopularDestination: FC<PopularDestinationProps> = ({ componentHeader }) => {
     const state = useDestinationSlice();
     return (
          <div className="mx-auto w-[95%] mb-10">
               <div className="flex justify-between items-center py-5">
                    <h6 className="text-[40px] font-[400px] capitalize font-josefine leading-[45px]">
                         {componentHeader}
                    </h6>
                    {/* <div className="flex gap-3">
                         <button className="border border-gray-900 p-2 rounded-md">
                              <AiOutlineLeft size={22} />
                         </button>
                         <button className="border border-gray-900 p-2 rounded-md">
                              <AiOutlineRight size={24} />
                         </button>
                    </div> */}
               </div>
               {/* <div className="grid xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 justify-center items-center gap-10 md:grid-cols-3"> */}
               <ReactOwlCarousel
                    className="owl-carousel owl-theme z-10"
                    margin={20}
                    loop
                    items={1}
                    responsive={{
                         0: {
                              items: 1.4,
                         },
                         400: {
                              items: 1,
                         },
                         600: {
                              items: 1.5,
                         },
                         700: {
                              items: 3.5,
                         },
                         1000: {
                              items: 4,
                         },
                    }}
                    nav={false}
               >
                    {state.destination2.map(({ gallery, subTitle, title, id }, i) => (
                         <CarouselItem2
                              path={`/${id.toString() as string}`}
                              key={i}
                              image={gallery[0]}
                              label={subTitle as unknown as string}
                              packageName={title}
                         />
                    ))}
               </ReactOwlCarousel>
               {/* </div> */}
          </div>
     );
};
