import { FC } from "react";
import { ContactForm } from "../contact-form";

interface IContactModalProps {
  SendEnquiry: () => void;
}

export const ContactModal: FC<IContactModalProps> = ({ SendEnquiry }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 bg-gray-950 bg-opacity-30 z-50 outline-none focus:outline-none">
        <div className="relative  my-6 mx-auto xl:w-[40%]">
          {/*content*/}
          <div className=" shadow-lg relative flex flex-col w-full bg-primary-500 outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold text-white">
                What is your desired place?
              </h3>
            </div>
            {/*body*/}
            <div className="relative p-3 flex-auto bg-white">
              <ContactForm showCancelBtn formLabel="" />
            </div>
            {/*footer*/}
            {/* <div className="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 bg-white rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => dispatch(toggleContactModal(false))}
              >
                Cancel
              </button>
              <button
                className="bg-primary-500 text-white active:bg-primary-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={SendEnquiry}
              >
                Send Enquiry
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
