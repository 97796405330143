import React, { FC } from "react";
import { usePackageSlice } from "../../redux/app";
import { useNavigate } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export interface PackageComponentProps {
  label: string;
  title?: string;
  thumbnail?: string;
}

export const Packages: FC<PackageComponentProps> = ({ label: dataName }) => {
  const navigate = useNavigate();
  const { packages } = usePackageSlice();
  return (
    <>
      <div className="w-full bg-opacity-30 flex justify-start items-center xl:px-10 px-5 my-5">
        <h6 className="text-[40px] capitalize font-sans font-semibold leading-[45px]">
          {dataName}
        </h6>
      </div>
      <ReactOwlCarousel
        className="owl-carousel owl-theme z-50 xl:px-10 px-5"
        margin={20}
        items={1}
        loop={false}
        responsive={{
          0: {
            items: 1,
          },
          400: {
            items: 1.5,
          },
          600: {
            items: 2,
          },
          700: {
            items: 3,
          },
          1000: {
            items: 4,
          },
        }}
        nav={false}
      >
        {packages.map(({ placeName, thumbnail }, i) => (
          <div key={i} className="shadow-md">
            <img src={thumbnail} alt={placeName} className="w-full h-[250px]" />
            <div className="pt-5 flex flex-col px-3 justify-center items-center pb-10">
              {/* <p className="text-gray-500 text-sm border-b-2 border-primary-500 py-5 capitalize">
                  {subCategory}
                </p> */}
              <h6 className="text-xl py-5 capitalize">{placeName}</h6>
              <button
                onClick={() => navigate(`/places/${placeName!}`)}
                className="bg-white border hover:shadow-lg my-2 rounded-md capitalize px-5 py-2 focus:outline-none"
                style={{ zIndex: 1000 }}
              >
                know more
              </button>
            </div>
          </div>
        ))}
      </ReactOwlCarousel>
    </>
  );
};
