import React, { FC } from "react";
import { Link } from "react-router-dom";

interface CarouselItem2Props {
     image: string;
     label: string;
     path: string;
     packageName: string;
}

export const CarouselItem2: FC<CarouselItem2Props> = ({ image, label, packageName, path }) => {
     return (
          <div
               className=" relative group z-10 transition-all duration-300 h-[350px] 2xl:w-full lg:w-full xl:w-full md:w-[230px] w-auto"
               style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
               }}
          >
               {/* <img src={image} alt="" className="object-cover" /> */}
               <div className="absolute z-50 flex justify-center items-center h-[30%] bottom-0 bg-gradient-to-t from-primary-500 via-transparent to-transparent w-full group-hover:h-full transition-all duration-500">
                    <p className="text-white text-2xl capitalize font-semibold border-t-2">{packageName}</p>
               </div>
               <div className="hidden group-hover:z-50 group-hover:flex w-full group-hover:absolute bottom-20 group-hover:transition-all flex justify-center">
                    <div className=" xl:w-[30%] mx-8 text-center rounded-md bg-primary-500 text-white p-3">
                         <Link to={`${path}`} className="capitalize">
                              View all
                         </Link>
                    </div>
               </div>
          </div>
     );
};
