import React from "react";
import { MainLayout } from "../../layout";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  Ads2,
  Ads3,
  CarouselItem,
  Packages,
  Packages2,
  PopularDestination,
  ReachOut,
} from "../../component";

export const Homepage = () => {
  return (
    <MainLayout>
      <OwlCarousel
        autoPlay
        autoplayTimeout={2000}
        smartSpeed={1000}
        className="owl-carousel owl-theme z-10"
        loop
        items={1}
        nav
        dots={false}
        navText={[
          '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          '<i class="fa fa-angle-right" aria-hidden="true"></i>',
        ]}
      >
        <CarouselItem
          title="Journeys To Extraordinary Places "
          image={require("../../assets/pic_4.jpeg")}
        />
      </OwlCarousel>
      <Ads2
        background={require("../../assets/parallax.jpg")}
        subTitle="Introducing Travel Adventures"
        label="Exclusive Services that will give you the pleasure of Personalization "
        desc=""
      />
      <PopularDestination componentHeader={`Our Packages`} />
      <Ads3 background={require("../../assets/parallax-2.jpeg")} />
      <Packages label="Popular Destinations" />
      <Packages2 label="Experiences" />
      {/* <TravStoryPage />*/}
      <ReachOut />
    </MainLayout>
  );
};
