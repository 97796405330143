import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { DestinationReducer, LayoutReducer, PackageReducer } from "./app";

const rootStates = combineReducers({
     layout: LayoutReducer,
     destination: DestinationReducer,
     packages: PackageReducer,
});

export const store = configureStore({
     reducer: rootStates,
});
