import clsx from "clsx";
import React, { FC, useState } from "react";
import { AiOutlineMenu, AiOutlinePhone } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { toggleContactModal } from "../../redux/app";

interface NavProps {}

interface LinkProps {
  label: string;
  path: string;
}

const links: LinkProps[] = [
  {
    label: "home",
    path: "/",
  },
  // {
  //   label: "destination",
  //   path: "/PopularDestination",
  // },
  // {
  //   label: "regions",
  //   path: "/regions",
  // },
  // {
  //   label: "blogs",
  //   path: "/TravStoryPage",
  // },
  // {
  //   label: "mission",
  //   path: "/mission",
  // },
  {
    label: "why us?",
    path: "/why-us",
  },
];

export const NavBar: FC<NavProps> = () => {
  const [navBar, setNavBar] = useState<boolean>(false);

  const handleNavBar = () => {
    setNavBar((prevState) => !prevState); // Toggle the state based on its previous value
  };

  const dispatch = useAppDispatch();

  return (
    <nav
      className={clsx(
        "fixed z-50 w-full px-3 transition-all duration-500  bg-opacity-50 backdrop-blur-md py-4 shadow-xl"
      )}
    >
      <div className="xl:flex items-center justify-between xl:gap-10 xl:flex-nowrap flex-wrap-reverse">
        <div className="flex justify-between items-center xl:-mb-0 -mb-20">
          <img
            src={
              "https://www.travtech.io/assets/travvellis-logobg-rm.png"
            }
            className="pb-5 w-[150px]"
            alt=""
          />
          <button
            type="button"
            onClick={handleNavBar}
            className="bg-primary-500 p-3 rounded-lg xl:hidden lg:hidden 2xl:hidden"
          >
            <AiOutlineMenu size={22} className="text-white" />
          </button>
        </div>
        <ul
          className={clsx(
            "xl:flex gap-6 items-center w-full",
            navBar ? "flex-col gap-3 text-left mt-20 items-start" : "hidden"
          )}
        >
          {links.map(({ label, path }) => (
            <li
              key={label}
              className="text-md xl:mt-0 mt-3 capitalize line-clamp-[20px] font-normal"
            >
              <Link to={path}>{label}</Link>
            </li>
          ))}
        </ul>
        <div
          className={clsx(
            "xl:w-[550px] xl:mt-0 mt-3 xl:visible 2xl:visible lg:visible",
            navBar ? "visible" : " invisible"
          )}
        >
          <ul className="flex items-center gap-5 justify-end">
            <li className="flex items-center gap-3">
              <AiOutlinePhone size={26} className="text-white" />
              <p className="text-md font-sans text-white">+91 8591556868</p>
            </li>
            <li>
              <button
                type="button"
                onClick={() => dispatch(toggleContactModal(true))}
                className="p-3 bg-primary-500 hover:bg-primary-600 text-white rounded-md text-md"
              >
                Contact us
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
