import React from "react";
import { MainLayout } from "../../layout";
import { usePackageSlice } from "../../redux/app";
import { useParams } from "react-router-dom";
import { ContactForm } from "../../component";

export const DestinationListPage = () => {
  const param = useParams();
  const { packages } = usePackageSlice();
  const packageContent = packages.find(
    (prop) => prop.placeName === param.placeName
  );
  return (
    <MainLayout>
      <div
        style={{
          backgroundImage: `url(${packageContent?.content[0].images[0]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="h-[60vh] bg-gray-900 w-full bg-opacity-10 flex justify-center items-center">
          <h6 className="text-4xl font-semibold text-white capitalize">
            {param.placeName}
          </h6>
        </div>
      </div>
      <div className="grid xl:grid-cols-12 gap-10 px-10 my-20 flex-wrap-reverse">
        <div className="xl:col-span-8">
          <h1 className="text-4xl font-semibold capitalize">
            {param.placeName}
          </h1>
          <p className="text-justify">{packageContent?.mainDesc}</p>
          {packageContent?.content.map(({ title, desc, images }, i) => (
            <div key={i} className="mt-20 flex flex-col gap-5">
              <h3 className="text-2xl font-semibold">{title}</h3>
              <p className="text-gray-500 text-justify">{desc}</p>
              <div className="grid xl:grid-cols-2 grid-cols-1 gap-3 flex-wrap items-center">
                {images.map((prop, i) => (
                  <img src={prop} className="flex-1 w-full" key={i} alt="" style={{ height: "400px" }}/>
                ))}
              </div>
            </div>
          ))}
          <div className="mt-10 text-md whitespace-pre-wrap">
            <p>{packageContent?.conclusion}</p>
          </div>
        </div>
        <div className="xl:col-span-4">
          <ContactForm formLabel="Contact us soon" />
        </div>
      </div>
    </MainLayout>
  );
};
