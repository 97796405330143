import React from "react";
import { MainLayout } from "../../layout";
import { usePackageSlice } from "../../redux/app";
import { useParams } from "react-router-dom";
import { ContactForm } from "../../component";

export const ExpPackagePage = () => {
  const { id } = useParams();
  const { expPackage } = usePackageSlice();
  const currentPackage = expPackage.find(
    (props) => props.id === parseInt(id as string)
  );
  return (
    <MainLayout>
      <div className="py-[150px] px-10 mx-auto container grid grid-cols-12 gap-8 items-start">
        <div className="xl:col-span-8 lg:col-span-8 md:col-span-6 col-span-12">
          <img style={{"height":"500px"}}
            src={
              currentPackage?.thumbnail[0]
            }
            className="w-full rounded-lg"
            alt=""
          />
          <h3 className="text-3xl font-semibold my-10">
            {currentPackage?.placeName}
          </h3>
          <p className="text-gray-500 whitespace-pre-line">
            {currentPackage?.mainDesc}
          </p>
          {currentPackage?.content.map(({ images, desc, title }, o) => (
            <div key={o} className="mt-10">
              <h6 className="text-2xl font-semibold capitalize">{title}</h6>
              <div className="grid mt-3 grid-cols-3 gap-3 items-start" >
                {images.map((props, i) => (
                  <img style={{"height":"300px"}} key={i} src={props} className="w-full" alt={title} />
                ))}
              </div>
              <p className="whitespace-pre-wrap mt-5">{desc}</p>
            </div>
          ))}
        </div>

        <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
          <ContactForm formLabel="Contact for us" />
        </div>
      </div>
    </MainLayout>
  );
};
